import React from "react";
import AlertDialog from "../dialog";

export default function LogOutConfirm({
  close,
  confirm,
  isOpen,
  numOfUnsavedReports,
}) {
  return (
    <AlertDialog
      title="Warning: Delete unsaved reports"
      message={`This will delete ${numOfUnsavedReports} unsaved ${
        numOfUnsavedReports == 1 ? "report" : "reports"
      }.`}
      okTitle="Delete"
      open={isOpen}
      handleClose={close}
      handleOk={confirm}
    />
  );
}
