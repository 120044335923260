import {
  convertObjectToArray,
  generateNextIdNeg,
  getLowestKeyOfObject,
} from "../modules/utils";

const CREATE = "CREATE";
const DELETE = "DELETE";

// Defaults
const DEFAULTS = ["my_actions", "my_categories", "my_faults", "my_reporttypes"];

// Actions declaration helpers
const declareCreate = (my_default) => `fleet_viper/${my_default}/${CREATE}`;
const declareDelete = (my_default) => `fleet_viper/${my_default}/${DELETE}`;

const declareAllActions = (defaults) => {
  const ACTIONS = {};
  defaults.map((my_default) => {
    ACTIONS[my_default] = {};
    ACTIONS[my_default][CREATE] = declareCreate(my_default);
    ACTIONS[my_default][DELETE] = declareDelete(my_default);
  });
  return ACTIONS;
};

// Actions
const ACTIONS = declareAllActions(DEFAULTS);

// Action creators
export const createDefault = ({ my_default, data }) => ({
  type: ACTIONS[my_default][CREATE],
  payload: { data },
});

export const deleteDefault = ({ my_default, id }) => ({
  type: ACTIONS[my_default][DELETE],
  payload: { id },
});

// Reducer helpers
const createState = () => {
  const my_state = {};
  DEFAULTS.map((my_default) => {
    my_state[`${my_default}`] = {};
  });
  return my_state;
};

// Reducer
export default function reducer(state = createState(), action) {
  let my_defaults;

  // Parse type.  eg.  fleet_viper/${my_default}/${sCREATE}
  const actionType = action.type.split("/");

  // Switch on verb of actionType
  switch (actionType[actionType.length - 1]) {
    case CREATE:
      my_defaults = { ...state[actionType[actionType.length - 2]] };
      let id = generateNextIdNeg(my_defaults);
      my_defaults[id] = {
        ...action.payload.data,
        id,
      };
      return {
        ...state,
        [actionType[actionType.length - 2]]: my_defaults,
      };

    case DELETE:
      my_defaults = { ...state[actionType[actionType.length - 2]] };
      delete my_defaults[action.payload.id];
      return { ...state, [actionType[actionType.length - 2]]: my_defaults };

    default:
      return state;
  }
}

// Selectors

export const selectDefault = ({ my_default, state }) =>
  state.my_defaults[my_default];

export const selectDefaultAsArray = ({ my_default, state }) =>
  convertObjectToArray(selectDefault({ my_default, state }));

export const selectLastDefaultId = ({ my_default, state }) => {
  return getLowestKeyOfObject(selectDefault({ my_default, state }));
};
