import * as React from "react";
import { connect } from "react-redux";

import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import Card from "@material-ui/core/Card";
import { Button, CardHeader, FormControl, TextField } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

import Select from "../select";
import { deleteRectification } from "common/modules/utils";
import InputDate from "../inputdate";
import { selectLastDefaultId } from "common/ducks/defaults";
import TextInput from "../textinput";
import { fetchActions, postDefault, selectToken } from "common/ducks/api";
const colors = MyTheme.colors;

const Rectifications = ({
  actions,
  changeCheck,
  createDefault,
  fetchActions,
  postDefault,
  current,
  deleteRectification,
  faults,
  users,
  handleChange,
  rectifications,
  setFunction,
  lastMyActionIndex,
  token,
}) => {
  return Object.keys(rectifications).map((id) => (
    <Card
      key={JSON.stringify(rectifications[id])}
      className={styles.card}
      variant="outlined"
      style={{ backgroundColor: colors.secondaryCardLight }}
    >
      <CardHeader
        subheader={`Rectification ${parseInt(id) + 1}`}
        action={
          <Button
            style={{ color: MyTheme.colors.error }}
            data-cy={`delete-rectification-${parseInt(id) + 1}`}
            onClick={() => {
              deleteRectification(id);
              changeCheck(rectifications[id].fault.id, "NC");
            }}
          >
            Delete
          </Button>
        }
      />
      <CardContent>
        <Rectification
          fetchActions={fetchActions}
          postDefault={postDefault}
          token={token}
          lastMyActionIndex={lastMyActionIndex}
          changeCheck={changeCheck}
          createDefault={createDefault}
          current={current && current[id]}
          index={id}
          actions={actions}
          faults={faults}
          users={users}
          rectification={rectifications[id]}
          handleChange={handleChange}
          setFunction={(rectification) =>
            setFunction({ ...rectifications, [id]: rectification })
          }
        />
      </CardContent>
    </Card>
  ));
};

const Rectification = ({
  actions,
  changeCheck,
  createDefault,
  current,
  faults,
  index,
  users,
  handleChange,
  rectification,
  setFunction,
  lastMyActionIndex,
  token,
  fetchActions,
  postDefault,
}) => {
  return (
    <div
      className={styles.rectificationContainer}
      data-cy={`rectification${index}`}
    >
      <div className={styles.faultActionContainer}>
        <div>
          <Select
            dataCy="fault"
            label="Fault"
            value={rectification.fault}
            options={faults}
            renderOption={(option) => {
              return `${option.im_no ? option.im_no + ": " : ""}${option.name}`;
            }}
            setValue={(event, value) => {
              let isEditing = (value && value.name) !== current?.fault?.name;
              if (isEditing) {
                changeCheck(value?.id, "D", rectification?.fault?.id);
              }
              handleChange(
                `rectification${rectification.id}.fault`,
                () => setFunction({ ...rectification, fault: value }),
                isEditing
              );
            }}
          />
        </div>
        <div>
          <Select
            dataCy="action"
            label="Action"
            value={rectification.action}
            options={actions}
            renderOption={(option) => {
              return option.name;
            }}
            setValue={(event, value) => {
              let isEditing = (value && value.name) !== current?.action?.name;
              handleChange(
                `rectification${rectification.id}.action`,
                () => setFunction({ ...rectification, action: value }),
                isEditing
              );
            }}
            createNewItem={async ({ data }) => {
              await postDefault({ token, data, type: "action" });
              await fetchActions({ token });
            }}
          />
        </div>
        <div>
          <Select
            dataCy="rectifier"
            label="Rectified by"
            value={rectification.rectifier}
            options={users}
            renderOption={(option) => {
              return `${option.first_name} ${option.last_name}`;
            }}
            setValue={(event, value) => {
              let isEditing = (value && value.id) !== current?.rectifier?.id;

              handleChange(
                `rectification${rectification.id}.rectifier`,
                () => setFunction({ ...rectification, rectifier: value }),
                isEditing
              );
            }}
          />
        </div>
      </div>

      <div className={styles.descriptionAndWarranty}>
        <TextInput
          dataCy="details"
          label="Details"
          defaultValue={rectification?.description || ""}
          onBlur={(e) => {
            let isEditing = e.target.value !== current?.description;
            handleChange(
              `rectification${rectification.id}.description`,
              () => {
                setFunction({
                  ...rectification,
                  description: e.target.value,
                });
              },
              isEditing
            );
          }}
        />

        {rectification?.action?.name.toLowerCase().includes("fitted") && (
          <div className={styles.partContainer}>
            <div className={styles.part}>
              <TextInput
                label="New part"
                defaultValue={rectification?.warranty?.description || ""}
                onBlur={(e) => {
                  let isEditing =
                    e.target.value !== current?.warranty?.description;
                  handleChange(
                    `rectification${rectification.id}.warranty`,
                    () =>
                      setFunction({
                        ...rectification,
                        warranty: {
                          ...rectification.warranty,
                          description: e.target.value,
                        },
                      }),
                    isEditing
                  );
                }}
              />
            </div>
            <div className={styles.expiry}>
              <InputDate
                label="Warranty Expiry"
                variant="outlined"
                value={rectification?.warranty?.warranty_expiry}
                onChange={(value) => {
                  let isEditing =
                    Date.parse(value) !==
                    (current?.warranty?.warranty_expiry &&
                      Date.parse(current.warranty.warranty_expiry));

                  handleChange(
                    `rectification${rectification.id}.warranty`,
                    () =>
                      setFunction({
                        ...rectification,
                        warranty: {
                          ...rectification.warranty,
                          warranty_expiry: value,
                        },
                      }),
                    isEditing
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: selectToken(state),
    lastMyActionIndex: selectLastDefaultId({
      my_default: "my_actions",
      state,
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postDefault: async ({ token, data, type }) => {
      await dispatch(postDefault({ token, data, type }));
    },
    fetchActions: async ({ token, data, type }) => {
      await dispatch(fetchActions({ token }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rectifications);

// const report.warranty = {description: "Windsor 270 wing mirror",
// id: 3420,
// warranty_expiry: "2022-12-10"}
