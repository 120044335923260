import React from "react";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";

import styles from "./styles.module.css";

import { vehicleSearchFilter } from "common/modules/search-filters";

import { MyTheme } from "common/modules/colors";

import {
  fetchActions,
  fetchFaults,
  fetchReportTypes,
  fetchVehicles,
  deleteVehicle,
  selectVehiclesAsArray,
  selectVehiclesDownloaded,
  selectToken,
  selectVehicles,
  fetchReports,
  selectFetchingVehicles,
} from "common/ducks/api";
import VehiclesSearchResults from "../vehiclessearchresults";
import { Link } from "react-router-dom";
import { deleteMyVehicle, selectMyVehicles } from "common/ducks/vehicle";
import SearchField from "../searchfield";
import FloatingActionButton from "../floatingactionbutton";
import IconButtonHorizontal from "../iconbuttonhorizontal";

const colors = MyTheme.colors;

class VehicleSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      filtered: [],
      vehicles: [],
      isReady: false,
    };
  }

  componentDidMount() {
    this.initialiseDefaults();
  }

  initialiseDefaults = async () => {
    // Get actions from storage
    // Save to state
    // Get actions from server after last_updated
    // Merge into state
    this.props.getActions({ token: this.props.token, last_updated: null });
    this.props.getFaults({ token: this.props.token, last_updated: null });
    this.props.getReportTypes({ token: this.props.token, last_updated: null });
    await this.props.getVehicles({
      token: this.props.token,
      last_updated: this.props.vehiclesDownloaded,
    });
    this.SearchFilterFunction("");
  };

  // @TODO Only need to pass id and reg.  No need for full vehicles here
  filteredToVehicles = () => {
    let vehicles = this.state.filtered.map((id) => this.props.vehicles[id]);
    return vehicles;
  };

  SearchFilterFunction = (searchInput, vehicles = this.props.vehicles) => {
    const filtered = vehicleSearchFilter(vehicles, searchInput);

    this.setState({
      filtered: filtered,
      searchInput: searchInput,
    });
  };

  handleDelete = async ({ id }) => {
    if (id < 0) {
      await this.props.deleteMyVehicle({ token: this.props.token, id });
    } else {
      await this.props.deleteVehicle({ token: this.props.token, id });
      await this.props.getReports({
        token: this.props.token,
        vehicle: id,
        last_updated: this.props.vehiclesDownloaded,
      });
    }
    await this.props.getVehicles({
      token: this.props.token,
      last_updated: this.props.vehiclesDownloaded,
    });

    this.SearchFilterFunction("");
  };

  handleInput = (input) => {
    this.setState({ searchInput: input });
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.inputContainer}>
            <SearchField
              label="Vehicles"
              value={this.state.searchInput}
              onChange={(e) => this.SearchFilterFunction(e.target.value)}
            />
          </div>
          <div className={styles.createContainer} data-cy="create-vehicle">
            <IconButtonHorizontal
              title="Create Vehicle"
              icon="add"
              iconStyle="outlined"
              color={MyTheme.colors.primary}
              // activeColor={MyTheme.colors.onPrimary}
              link="/vehicles/new"
            />
          </div>
        </div>
        {this.state.filtered.length > 0 ? (
          <VehiclesSearchResults
            vehicles={this.filteredToVehicles()}
            handleDelete={this.handleDelete}
          />
        ) : null}
        {this.props.fetching && (
          <div data-cy="fetching">
            <LinearProgress />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: selectToken(state),
    vehicles: { ...selectVehicles(state), ...selectMyVehicles(state) },
    vehiclesDownloaded: selectVehiclesDownloaded(state),
    fetching: selectFetchingVehicles(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicles: async ({ token, last_updated }) => {
      await dispatch(fetchVehicles({ token, last_updated }));
    },
    getActions: ({ token, last_updated }) => {
      dispatch(fetchActions({ token, last_updated }));
    },
    getFaults: ({ token, last_updated }) => {
      dispatch(fetchFaults({ token, last_updated }));
    },
    getReportTypes: ({ token, last_updated }) => {
      dispatch(fetchReportTypes({ token, last_updated }));
    },
    deleteMyVehicle: async ({ id }) => {
      await dispatch(deleteMyVehicle({ id }));
    },
    deleteVehicle: async ({ token, id }) => {
      await dispatch(deleteVehicle({ token, id }));
    },
    getReports: async ({ token, last_updated, vehicle }) => {
      await dispatch(fetchReports({ token, last_updated, vehicle }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSearch);
