import * as React from "react";
import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import vehicles from "../vehiclessearch";
import { Link, useRouteMatch } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";
import { FixedSizeList } from "react-window";
import { AutoSizer } from "react-virtualized";
import AlertDialog from "../dialog";
import {
  datePlusWeeks,
  dateToDDMMYYYY,
  hasBeenSixWeeks,
} from "common/modules/date-utils";

// const VehiclesSearchResults = ({ vehicles }) => <div>Hello world!</div>;

const Item = ({ vehicle }) => {
  return (
    <div data-cy={`vehicle-${vehicle.reg}`}>
      {`${vehicle.reg} - ${vehicle.body_type} - ${
        hasBeenSixWeeks(vehicle.last_six_weekly?.inspection_date)
          ? "Six-Weekly due now"
          : `Six-Weekly date: ${dateToDDMMYYYY(
              datePlusWeeks(6, vehicle.last_six_weekly.inspection_date)
            )}`
      }`}
    </div>
  );
};

const ListItemLink = (props) => {
  return <ListItem button={true} divider={true} component={Link} {...props} />;
};

class VehiclesSearchResults extends React.PureComponent {
  constructor(props) {
    super(props);
    this.itemSize = 46;
    this.state = { isDialogOpen: false, itemRightClicked: null };
  }

  handleRightClick = (e, itemIndex) => {
    this.setState({ isDialogOpen: true, itemRightClicked: itemIndex });
    e.preventDefault();
    return false;
  };

  handleDelete = () => {
    this.props.handleDelete({
      index: this.state.itemRightClicked,
      id: this.props.vehicles[this.state.itemRightClicked].id,
    });
    this.setState({ isDialogOpen: false });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  RenderRow = ({ index, style }) => (
    <ListItemLink
      onContextMenu={(e) => this.handleRightClick(e, index)}
      key={this.props.vehicles[index].id}
      to={`/vehicles/${this.props.vehicles[index].id}`}
      style={style}
    >
      <Item vehicle={this.props.vehicles[index]} />
    </ListItemLink>
  );

  render() {
    return (
      <div className={styles.container} data-cy="vehicles-list">
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={this.itemSize}
              itemCount={this.props.vehicles.length}
            >
              {this.RenderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
        <AlertDialog
          title="Delete vehicle?"
          message="Do you want to delete this vehicle?\nThis can not be undone!"
          okTitle="Delete"
          open={this.state.isDialogOpen}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDelete}
        />
      </div>
    );
  }
}

export default VehiclesSearchResults;
