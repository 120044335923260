// Actions
const APP_CONNECT = "fleet_viper/app/APP_CONNECT";
const APP_DISCONNECT = "fleet_viper/app/APP_DISCONNECT";
const APP_SET_ONLINE = "fleet_viper/app/APP_SET_ONLINE";

// Action creators
export const appConnect = () => ({ type: APP_CONNECT });
export const appDisconnect = () => ({ type: APP_DISCONNECT });
export const appSetOnline = (status) => ({
  type: APP_SET_ONLINE,
  payload: status,
});

// Reducer
export default function reducer(state = { online: false }, action) {
  switch (action.type) {
    case APP_CONNECT:
      return { ...state, online: true };

    case APP_DISCONNECT:
      return { ...state, online: false };
    case APP_SET_ONLINE:
      return { ...state, online: action.payload };

    default:
      return state;
  }
}

// Selectors
export const selectOnline = (state) => state.app.online;
