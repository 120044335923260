import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.css";
import Badge from "@material-ui/core/Badge";

const IconButtonHorizontal = ({
  activeColor,
  badgeCount,
  color,
  dataCy,
  icon,
  iconStyle,
  link,
  title,
  onClick,
}) => (
  <NavLink
    onClick={onClick}
    to={link}
    className={styles.container}
    style={{ color: color }}
    activeStyle={{
      fontWeight: "bold",
      color: activeColor,
    }}
  >
    <div className={styles.inner} data-cy={dataCy}>
      <i className={`material-icons${iconStyle ? `-${iconStyle}` : ""}`}>
        {icon}
      </i>

      <Badge badgeContent={badgeCount} color="secondary">
        <div className={styles.text}>{title}</div>
      </Badge>
    </div>
  </NavLink>
);

export default IconButtonHorizontal;
