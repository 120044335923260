export const FAULT_DEFAULT_STATE = {
  id: "",
  name: "",
  im_no: "",
  category: "",
  expanded: false,
};
export const ACTION_DEFAULT_STATE = { id: "", name: "", expanded: false };
export const USER_DEFAULT_STATE = {
  id: "",
  first_name: "",
  last_name: "",
  expanded: false,
};

export const DESCRIPTION_DEFAULT_STATE = "";

export const WARRANTY_DEFAULT_STATE = {
  description: "",
  warranty_expiry: new Date(),
  expanded: false,
};

export const RECTIFICATION_DEFAULT_STATE = {
  expanded: true,
  fault: { ...FAULT_DEFAULT_STATE },
  action: { ...ACTION_DEFAULT_STATE },
  description: DESCRIPTION_DEFAULT_STATE,
  warranty: { ...WARRANTY_DEFAULT_STATE },
  rectifier: { ...USER_DEFAULT_STATE },
};

export const PART_DEFAULT_STATE = {
  expanded: true,
  report: null,
  date_fitted: "",
  part_number: "",
  description: "",
  nett: "",
  quantity: 1,
};

export const REPORT_INFO_DEFAULT_STATE = {
  expanded: true,
  reportType: { name: "", expanded: false },
  inspectionDate: { date: new Date(), expanded: false },
  inspector: { ...USER_DEFAULT_STATE },
};

export const VEHICLE_INFO_DEFAULT_STATE = {
  expanded: true,
  odometer: { reading: "", expanded: false, unit: "km" },
  tachograph: { date: null, expanded: false },
  vehicle: null,
};

export const CHECKED_INFO_DEFAULT_STATE = {
  checkedDate: { date: new Date() },
  checker: { ...USER_DEFAULT_STATE },
};

export const TYRE_CHECK_DEFAULT_STATE = { psi: null, mm: null };

const makeTyreCheckDefault = (tyre_number) => ({
  ...TYRE_CHECK_DEFAULT_STATE,
  tyre_number,
});

export const makeReportTyreChecksDefault = (tyres = []) => {
  return [...Array(13)].map((_, index) =>
    tyres[index] ? tyres[index] : makeTyreCheckDefault(index)
  );
};

export const makeReportTyreChecksDefaultWithExpanded = (
  tyres = [],
  expanded = false
) => {
  return { tyres: makeReportTyreChecksDefault(tyres), expanded };
};

export const VEHICLE_DEFAULT_STATE = {
  reg: "",
  make: "",
  model: "",
  body_type: "",
  fleet_no: "",
  year: "",
  chassis_no: "",
  //@TODO Select default state dependant on user settings
  // company: {},
};

const defaultStates = {
  CHECKED_INFO: CHECKED_INFO_DEFAULT_STATE,
  FAULT: FAULT_DEFAULT_STATE,
  ACTION: ACTION_DEFAULT_STATE,
  RECTIFICATION: RECTIFICATION_DEFAULT_STATE,
  REPORT_INFO: REPORT_INFO_DEFAULT_STATE,
  VEHICLE_INFO: VEHICLE_INFO_DEFAULT_STATE,
  VEHICLE: VEHICLE_DEFAULT_STATE,
  PART: PART_DEFAULT_STATE,
};

export const REPORT_DEFAULT_STATE = {
  checkedInfo: { ...CHECKED_INFO_DEFAULT_STATE },
  vehicleInfo: { ...VEHICLE_INFO_DEFAULT_STATE },
  reportInfo: { ...REPORT_INFO_DEFAULT_STATE },
  rectifications: [],
  tyres: [],
};

export default defaultStates;
