import { generateNextIdNeg } from "../modules/utils";

// Actions
const MY_VEHICLES_CREATE = "fleet_viper/my_vehicles/MY_VEHICLES_CREATE";
const MY_VEHICLES_OPEN = "fleet_viper/my_vehicles/MY_VEHICLES_OPEN";
const MY_VEHICLES_DUPLICATE = "fleet_viper/my_vehicles/MY_VEHICLES_DUPLICATE";
const MY_VEHICLES_DELETE = "fleet_viper/my_vehicles/MY_VEHICLES_DELETE";

const MY_VEHICLES_SAVE = "fleet_viper/my_vehicles/MY_VEHICLES_SAVE";
const MY_VEHICLES_CANCEL = "fleet_viper/my_vehicles/MY_VEHICLES_CANCEL";

// Action creators
export const createVehicle = () => ({
  type: MY_VEHICLES_CREATE,
});
export const openVehicle = ({ id }) => ({
  type: MY_VEHICLES_OPEN,
  payload: { id },
});
export const duplicateVehicle = ({ id, vehicle }) => ({
  type: MY_VEHICLES_DUPLICATE,
  payload: { id, vehicle },
});
export const deleteMyVehicle = ({ id }) => ({
  type: MY_VEHICLES_DELETE,
  payload: { id },
});

export const saveVehicle = ({ id, vehicle }) => ({
  type: MY_VEHICLES_SAVE,
  payload: { id, vehicle },
});

export const cancelVehicle = ({ id }) => ({
  type: MY_VEHICLES_CANCEL,
  payload: { id },
});

// Reducer
export default function reducer(
  state = { my_vehicles: {}, current_vehicle: null },
  action
) {
  let my_vehicles;
  switch (action.type) {
    case MY_VEHICLES_CREATE:
      my_vehicles = { ...state.my_vehicles };
      let id = generateNextIdNeg(my_vehicles);
      my_vehicles[id] = undefined;
      return {
        ...state,
        current_vehicle: {
          id,
          data: null,
        },
        my_vehicles,
      };

    case MY_VEHICLES_OPEN:
      return {
        ...state,
        current_vehicle: {
          id: action.payload.id,
          data: state.my_vehicles[action.payload.id],
        },
      };

    case MY_VEHICLES_DUPLICATE:
      return {
        ...state,
        current_vehicle: {
          id: action.payload.id,
          data: action.payload.vehicle,
        },
      };

    case MY_VEHICLES_DELETE:
      my_vehicles = { ...state.my_vehicles };
      delete my_vehicles[action.payload.id];
      return { ...state, my_vehicles };

    case MY_VEHICLES_SAVE:
      my_vehicles = { ...state.my_vehicles };
      my_vehicles[action.payload.id] = {
        ...action.payload.vehicle,
        id: action.payload.id,
      };

      return { ...state, current_vehicle: null, my_vehicles };

    case MY_VEHICLES_CANCEL:
      my_vehicles = { ...state.my_vehicles };
      if (!state.current_vehicle.data) {
        delete my_vehicles[action.payload.id];
      }
      return { ...state, current_vehicle: null, my_vehicles };

    default:
      return state;
  }
}

export const selectMyVehicles = (state) => state.my_vehicles.my_vehicles;
export const selectMyVehicleById = (state, id) =>
  state.my_vehicles.my_vehicles[id];

export const selectMyVehicleRegById = (state, id) =>
  state.my_vehicles.my_vehicles[id].reg;

export const selectCurrentVehicle = (state) =>
  state.my_vehicles.current_vehicle;

export const selectInspecting = (state) =>
  state.my_vehicles.current_vehicle !== null;

export const selectMyVehiclesCount = (state) => {
  try {
    return Object.keys(state.my_vehicles.my_vehicles).length;
  } catch {
    return "0";
  }
};
