import React from "react";
import styles from "./styles.module.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Search } from "@material-ui/icons";

const SearchField = ({ label, onChange, value }) => (
  <div className={styles.container}>
    <TextField
      className={styles.input}
      id="outlined-basic"
      variant="outlined"
      placeholder="Search here..."
      label={label}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  </div>
);

export default SearchField;
