import React from "react";
import styles from "./styles.module.css";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CreateDefault from "../createdefault";

const filter = createFilterOptions();

export default function Select({
  createNewItem,
  dataCy,
  label,
  options,
  renderOption,
  value,
  setValue,
}) {
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });

  const handleClose = () => {
    setDialogValue({
      name: "",
    });

    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      name: dialogValue.name,
      // @TODO pass in createNewItem()
      // Need to create my_faults my_actions ... etc
      // Can I create them with helper, to avoid hardcoding it all?
    });
    createNewItem({ data: { name: dialogValue.name } });

    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        fullWidth={true}
        value={value}
        groupBy={(option) => option.category?.name}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
            });
          } else {
            setValue(event, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "" && label !== "Fault") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          if (option.hasOwnProperty("first_name")) {
            option.name =
              option.first_name === ""
                ? ""
                : `${option.first_name} ${option.last_name}`;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={renderOption}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            data-cy={dataCy}
            label={label}
            variant="outlined"
            margin="normal"
            style={{ backgroundColor: "white" }}
          />
        )}
      />
      {label !== "Fault" && (
        <CreateDefault
          dialogValue={dialogValue}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          label={label}
          open={open}
          setDialogValue={setDialogValue}
        />
      )}
    </React.Fragment>
  );
}
