const development = {
  api: {
    URL: process.env.REACT_APP_API_BASE_URL,
  },
};

const production = {
  api: {
    URL:
      process.env.REACT_APP_API_BASE_URL ||
      "https://api.fleetviper.com/backend",
  },
};

const config =
  process.env.REACT_APP_STAGE === "development" ||
  process.env.NODE_ENV === "development"
    ? development
    : production;

export default {
  // Add common config values here
  ...config,
};
