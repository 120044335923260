import React from "react";
import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const colours = MyTheme.colors;

export default function ResetPassword({ errors, reset }) {
  const [username, setUsername] = React.useState("");

  const handleResetPassword = (e) => {
    e.preventDefault();
    reset({ username: username });
  };

  return (
    <div className={styles.container}>
      <Card className={styles.formcontainer}>
        <div
          className={styles.logocontainer}
          style={{ backgroundColor: colours.primary }}
        >
          <CardMedia
            image={process.env.PUBLIC_URL + "/images/logo-name-white.svg"}
            title="FleetViper"
            style={{
              height: "4em",
              backgroundSize: "11em",
            }}
          />
        </div>

        <ul data-cy="loginerrors" className=".error-messages">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
        <form
          className={styles.form}
          onSubmit={handleResetPassword}
          data-cy="Reset form"
        >
          <TextField
            label="Username"
            variant="outlined"
            id="username"
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            data-cy="username"
          />

          <CardActions className={styles.actions}>
            <Button
              className={styles.submit}
              variant="contained"
              size="large"
              color="primary"
              id="reset"
              type="submit"
              data-cy="reset"
            >
              Reset password
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
