import { PURGE } from "redux-persist";
import { deleteMyReport } from "./report";
import { deleteMyVehicle } from "./vehicle";
import { deleteRequest, put, URL } from "../services/api";

import {
  requestToken,
  apiReportsToAppReports,
  apiVehiclesToAppVehicles,
  get,
  apiToApp,
  post,
} from "../services/api";

import {
  datePlusWeeks,
  dateToYYYYMMDD,
  hasBeenSixWeeks,
  isFutureDate,
} from "../modules/date-utils";
import { startOfISOWeek } from "date-fns";

// Types
const API = "fleet_viper/api";
const REQUEST_START = `${API}/REQUEST_START`;
const REQUEST_END = `${API}/REQUEST_END`;
const REQUEST_ERROR = `${API}/REQUEST_ERROR`;
export const FETCH_REPORTS = `${API}/FETCH_REPORTS`;
export const FETCH_REPORTS_PENDING = `${API}/FETCH_REPORTS_PENDING`;
const FETCH_VEHICLES = `${API}/FETCH_VEHICLES`;

const SET_REPORTS = `${API}/SET_REPORTS`;
const SET_REPORTS_PENDING = `${API}/SET_REPORTS_PENDING`;
const SET_VEHICLES = `${API}/SET_VEHICLES`;
const SET_VEHICLE = `${API}/SET_VEHICLE`;

export const POST_REPORT = `${API}/POST_REPORT`;
export const PUT_REPORT = `${API}/PUT_REPORT`;
export const DELETE_REPORT = `${API}/DELETE_REPORT`;
export const POST_VEHICLE = `${API}/POST_VEHICLE`;
export const PUT_VEHICLE = `${API}/PUT_VEHICLE`;
export const DELETE_VEHICLE = `${API}/DELETE_VEHICLE`;
export const CHANGE_PASSWORD = `${API}/CHANGE_PASSWORD`;

export const POST_DEFAULT = `${API}/POST_DEFAULT`;

const SET_POSTED = `${API}/SET_POSTED`;
const REMOVE_POSTED = `${API}/REMOVE_POSTED`;

const SET_PASSWORD_CHANGE_STATUS = `${API}/SET_PASSWORD_CHANGE_STATUS`;

const FETCH_TOKEN = `${API}/FETCH_TOKEN`;
const SET_TOKEN = `${API}/SET_TOKEN`;

const FETCH_USER = `${API}/FETCH_USER`;
const SET_USER = `${API}/SET_USER`;
const FETCH_USERS = `${API}/FETCH_USERS`;
const SET_USERS = `${API}/SET_USERS`;

const FETCH_ACTIONS = `${API}/FETCH_ACTIONS`;
const SET_ACTIONS = `${API}/SET_ACTIONS`;
const FETCH_CATEGORIES = `${API}/FETCH_CATEGORIES`;
const SET_CATEGORIES = `${API}/SET_CATEGORIES`;
const FETCH_FAULTS = `${API}/FETCH_FAULTS`;
const SET_FAULTS = `${API}/SET_FAULTS`;
const FETCH_REPORT_TYPES = `${API}/FETCH_REPORT_TYPES`;
const SET_REPORT_TYPES = `${API}/SET_REPORT_TYPES`;

// Reducer

const INITIAL_STATE = {
  token: null,
  fetching: {},
  posted: {},
  passwordChangeStatus: null,
  reports: {},
  vehicles: {
    downloaded: null,
    vehicles: {},
  },
  users: { downloaded: null, order: [], users: {} },
  actions: { downloaded: null, order: [], actions: {} },
  categories: { downloaded: null, order: [], categories: {} },
  faults: { order: [], faults: {} },
  report_types: { order: [], report_types: {} },
};

export default function reducer(state = INITIAL_STATE, action) {
  let fetching = { ...state.fetching };
  let posted = { ...state.posted };
  let reports;
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;

    case REQUEST_START:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.payload]: { timestamp: new Date() },
        },
      };

    case REQUEST_END:
      delete fetching[action.payload];
      return {
        ...state,
        fetching,
      };

    case REQUEST_ERROR:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.payload.label]: {
            ...state.fetching[action.payload.label],
            error: action.payload.error,
          },
        },
      };

    case SET_PASSWORD_CHANGE_STATUS:
      return {
        ...state,
        passwordChangeStatus: action.payload,
      };

    case SET_REPORTS:
      // If fetch is cancelled
      if (!state.fetching[FETCH_REPORTS]) return state;
      reports = apiReportsToAppReports(state.reports, action.payload);

      return {
        ...state,
        reports,
      };

    case SET_REPORTS_PENDING:
      // If fetch is cancelled
      if (!state.fetching[FETCH_REPORTS_PENDING]) return state;
      reports = apiReportsToAppReports(state.reports, action.payload, true);

      return {
        ...state,
        reports,
      };

    case SET_VEHICLES:
      // If fetch is cancelled
      if (!state.fetching[FETCH_VEHICLES]) return state;
      let vehicles = apiVehiclesToAppVehicles(state.vehicles, action.payload);

      return {
        ...state,
        vehicles,
      };

    case SET_VEHICLE:
      let vehiclesAfterSetVehicle = {
        ...state.vehicles,
      };

      vehiclesAfterSetVehicle.vehicles[action.payload.id] = {
        ...action.payload,
      };

      return { ...state, vehicles: vehiclesAfterSetVehicle };

    case DELETE_REPORT:
      let reportsWithDeleted = { ...state.reports };
      delete reportsWithDeleted[action.payload.vehicle].reports[
        action.payload.id
      ];
      reportsWithDeleted[action.payload.vehicle].order.splice(
        reportsWithDeleted[action.payload.vehicle].order.indexOf(
          action.payload.id
        ),
        1
      );
      return {
        ...state,
        reports: reportsWithDeleted,
      };

    case SET_USERS:
      let users = apiToApp(state.users, action.payload["results"], "users");
      return {
        ...state,
        users,
      };

    case SET_ACTIONS:
      let actions = apiToApp(
        state.actions,
        action.payload["results"],
        "actions"
      );
      return {
        ...state,
        actions,
      };

    case SET_CATEGORIES:
      let categories = apiToApp(
        state.categories,
        action.payload["results"],
        "categories"
      );
      return {
        ...state,
        categories,
      };

    case SET_FAULTS:
      let faults = apiToApp(state.faults, action.payload["results"], "faults");
      return {
        ...state,
        faults,
      };

    case SET_REPORT_TYPES:
      let report_types = apiToApp(
        state.report_types,
        action.payload["results"],
        "report_types"
      );
      return {
        ...state,
        report_types,
      };

    case SET_TOKEN:
      return { ...state, token: action.payload };

    case SET_USER:
      return { ...state, user: action.payload };

    case SET_POSTED:
      return {
        ...state,
        posted: { ...posted, [action.payload.label]: action.payload.id },
      };
    case REMOVE_POSTED:
      let postedWithoutId = { ...posted };
      delete postedWithoutId[action.payload];

      return {
        ...state,
        posted: { ...postedWithoutId },
      };

    default:
      return state;
  }
}

// Selectors
export const selectToken = (state) => state.api.token;
export const selectUser = (state) => state.api.user;
export const selectUserId = (state) => state.api.user.id;
export const selectFetching = (state) => state.api.fetching;
export const selectChangePasswordStatus = (state) => {
  return state.api.passwordChangeStatus;
};
export const selectFetchingReports = (state) =>
  state.api.fetching[FETCH_REPORTS] != null;
export const selectFetchingVehicles = (state) =>
  state.api.fetching[FETCH_VEHICLES] != null;

export const selectPostingVehicleStatus = (state, id) =>
  state.api.fetching[`${POST_VEHICLE}/${id}`];

export const selectPostingReportStatus = (state, vehicleId, reportId) =>
  state.api.fetching[`${POST_REPORT}/${vehicleId}/${reportId}`];

export const selectPostedId = (state, label) => {
  return state.api.posted[label];
};
export const selectRequestByLabel = (state, label) => state.api.fetching[label];
export const selectTachographNextDate = (state, vehicle) => {
  try {
    const reportIndex = state.api.reports[vehicle].order[0];
    const report = state.api.reports[vehicle].reports[reportIndex];
    return new Date(report.tachograph.next_calibration_date);
  } catch {
    return null;
  }
};
export const selectReports = (state, vehicle) => {
  try {
    return state.api.reports[vehicle].reports;
  } catch {
    return {};
  }
};

export const selectReportsByVehicle = (state, vehicle) => {
  try {
    const reports = state.api.reports[vehicle].reports;
    return reports || {};
  } catch {
    return {};
  }
};

export const selectRectificationsByVehicle = (state, vehicle) => {
  const reports = selectReportsAsArray(state, vehicle);
  const rectifications = [];

  reports.forEach((report) =>
    // On delete, could be undefined
    report?.rectifications.forEach((rectification) =>
      rectifications.push({
        ...rectification,
        vehicle: report.vehicle,
        date: report.inspection_date,
      })
    )
  );
  return rectifications;
};

export const selectWarrantiesByVehicle = (state, vehicle) => {
  const rectifications = selectRectificationsByVehicle(state, vehicle);
  const warranties = [];

  rectifications.forEach((rectification) => {
    if (rectification.warranty?.description) {
      warranties.push({
        ...rectification.warranty,
        vehicle,
        report: rectification.report,
      });
    }
  });
  warranties.sort(
    (a, b) => new Date(a.warranty_expiry) - new Date(b.warranty_expiry)
  );
  return warranties;
};

export const selectActiveWarrantiesByVehicle = (state, vehicle) => {
  const rectifications = selectRectificationsByVehicle(state, vehicle);
  const warranties = [];

  rectifications.forEach((rectification) => {
    if (
      rectification.warranty?.description &&
      isFutureDate(rectification.warranty?.warranty_expiry)
    ) {
      warranties.push({
        ...rectification.warranty,
        vehicle,
        report: rectification.report,
      });
    }
  });
  warranties.sort(
    (a, b) => new Date(a.warranty_expiry) - new Date(b.warranty_expiry)
  );
  return warranties;
};

export const selectReportById = (state, vehicle, id) => {
  return state.api.reports[vehicle].reports[id];
};

export const selectReportsDownloaded = (state) => {
  try {
    return state.api.reports.downloaded;
  } catch {
    return null;
  }
};

export const selectNext = (state, vehicle) => {
  try {
    return state.api.reports[vehicle].next;
  } catch {
    return null;
  }
};

export const selectReportsAsArray = (state, vehicle) => {
  try {
    return state.api.reports[vehicle].order.map(
      (id) => state.api.reports[vehicle].reports[id]
    );
  } catch {
    return [];
  }
};
export const selectReportsOrderByVehicle = (state, vehicle) => {
  try {
    return state.api.reports[vehicle].order;
  } catch {
    return [];
  }
};

export const selectPendingReports = (state) => {
  try {
    const vehicles = Object.keys(state.api.reports).filter(
      (key) => key !== "downloaded"
    );
    let reports = {};
    vehicles.forEach((vehicle) => {
      let reportsByVehicle = selectUncheckedReportsByVehicle(state, vehicle);

      if (Object.keys(reportsByVehicle).length > 0) {
        reports[vehicle] = reportsByVehicle;
      }
    });
    return reports;
  } catch {
    return {};
  }
};

export const selectUncheckedReportsByVehicle = (state, vehicleID) => {
  try {
    let reports = {};
    Object.keys(state.api.reports[vehicleID].reports).map((reportID) => {
      let report = state.api.reports[vehicleID].reports[reportID];
      report["id"] = reportID;
      if (!report.checked_date) {
        reports[reportID] = report;
      }
    });
    return reports;
  } catch {
    return {};
  }
};

export const selectPendingReportsAsArray = (state) => {
  try {
    const vehicles = Object.keys(state.api.reports).filter(
      (key) => key !== "downloaded"
    );
    let reports = [];
    vehicles.map((vehicle) => {
      let reportsByVehicle = selectReportsAsArray(state, vehicle);
      reports = reports.concat(reportsByVehicle);
    });

    return reports;
  } catch {
    return [];
  }
};

export const selectPendingReportsCount = (state) => {
  return Object.keys(selectPendingReports(state)).length;
};

export const selectWarrantiesAsArray = (state, vehicle) => {
  try {
    let array = [];
    state.api.reports[vehicle].order.map((id) => {
      let report = state.api.reports[vehicle].reports[id];
      let rectifications = report.rectifications;
      let warranty_found = rectifications.some((rectification) => {
        return rectification.warranty.description
          ? rectification.warranty.warranty_expiry >= dateToYYYYMMDD(new Date())
          : false;
      });
      if (warranty_found) {
        array.push(report);
      }
    });
    return array;
  } catch {
    return [];
  }
};

export const selectWarrantiesIds = (state, vehicle) => {
  try {
    let array = [];
    state.api.reports[vehicle].order.map((id) => {
      let report = state.api.reports[vehicle].reports[id];
      let rectifications = report.rectifications;
      let warranty_found = rectifications.some((rectification) => {
        return rectification.warranty;
      });
      if (warranty_found) {
        array.push(id);
      }
    });
    return array;
  } catch {
    return [];
  }
};

export const selectLastUpdated = (state, vehicle) => {
  try {
    return state.api.reports[vehicle].downloaded;
  } catch {
    return null;
  }
};

export const selectActionsAsArray = (state) => {
  try {
    return state.api.actions.order.map((id) => state.api.actions.actions[id]);
  } catch {
    return [];
  }
};

export const selectUsersAsArray = (state) => {
  try {
    return state.api.users.order.map((id) => state.api.users.users[id]);
  } catch {
    return [];
  }
};

export const selectCategoriesAsArray = (state) => {
  try {
    return state.api.categories.order.map(
      (id) => state.api.categories.categories[id]
    );
  } catch {
    return [];
  }
};

export const selectFaults = (state) => {
  try {
    return state.api.faults.faults;
  } catch {
    return {};
  }
};

export const selectFaultsAsArray = (state) => {
  try {
    return state.api.faults.order.map((id) => state.api.faults.faults[id]);
  } catch {
    return [];
  }
};

export const selectReportTypesAsArray = (state) => {
  try {
    return state.api.report_types.order.map(
      (id) => state.api.report_types.report_types[id]
    );
  } catch {
    return [];
  }
};

export const selectVehicles = (state) => {
  try {
    return state.api.vehicles.vehicles;
  } catch {
    return [];
  }
};

export const selectVehiclesSchedule = (state) => {
  const vehicles = selectVehiclesAsArray(state);

  const vehiclesByWeek = vehicles.reduce((accumulator, vehicle) => {
    const lastInspectionDate = new Date(
      vehicle.last_six_weekly?.inspection_date
    );
    const nextInspectionDate = hasBeenSixWeeks(lastInspectionDate)
      ? new Date() // If inspection is due now, use today's date
      : datePlusWeeks(6, lastInspectionDate); // Otherwise, 6 weeks from the last inspection

    let weekStart = startOfISOWeek(nextInspectionDate);

    while (weekStart < datePlusWeeks(52, new Date())) {
      let yyyyMMDD = weekStart.toISOString().split("T")[0];

      if (!accumulator[yyyyMMDD]) {
        accumulator[yyyyMMDD] = [];
      }

      accumulator[yyyyMMDD].push(vehicle);

      // Calculate the next week's "yyyy-MM-DD" date as the key
      weekStart = startOfISOWeek(datePlusWeeks(6, weekStart));
    }

    return accumulator;
  }, {});

  // Sort the keys (dates) in ascending order
  const sortedKeys = Object.keys(vehiclesByWeek).sort();

  // Create a new object with sorted keys
  const sortedVehiclesByWeek = sortedKeys.reduce((acc, key) => {
    acc[key] = vehiclesByWeek[key];
    return acc;
  }, {});

  return sortedVehiclesByWeek;
};

export const selectVehiclesAsArray = (state) => {
  try {
    return Object.keys(state.api.vehicles.vehicles).map(
      (id) => state.api.vehicles.vehicles[id]
    );
  } catch {
    return [];
  }
};

export const selectVehiclesDownloaded = (state) => {
  try {
    return state.api.vehicles.downloaded;
  } catch {
    return null;
  }
};

export const selectVehicleById = (state, id) => {
  return state.api.vehicles.vehicles[id];
};

export const selectVehicleRegById = (state, id) => {
  return state.api.vehicles.vehicles[id]?.reg;
};

// action creators
export const requestStart = (label) => ({
  type: REQUEST_START,
  payload: label,
});
export const requestEnd = (label) => ({ type: REQUEST_END, payload: label });
export const requestError = (error, label) => ({
  type: REQUEST_ERROR,
  payload: { error, label },
});
export const setPasswordChangeStatus = (status) => ({
  type: SET_PASSWORD_CHANGE_STATUS,
  payload: status,
});
export const setPosted = ({ label, id }) => ({
  type: SET_POSTED,
  payload: { label, id },
});

export const removePosted = (label) => ({
  type: REMOVE_POSTED,
  payload: label,
});

export const setToken = (token) => ({ type: SET_TOKEN, payload: token });

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: users,
});

export const setActions = (actions) => ({
  type: SET_ACTIONS,
  payload: actions,
});

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
});
export const setFaults = (faults) => ({
  type: SET_FAULTS,
  payload: faults,
});

export const setReportTypes = (report_types) => ({
  type: SET_REPORT_TYPES,
  payload: report_types,
});

export const setReports = (data) => ({
  type: SET_REPORTS,
  payload: data,
});

export const setReportsPending = (data) => ({
  type: SET_REPORTS_PENDING,
  payload: data,
});

export const deleteApiReport = ({ id, vehicle }) => ({
  type: DELETE_REPORT,
  payload: { id, vehicle },
});

export const setVehicles = (data) => ({
  type: SET_VEHICLES,
  payload: data,
});

export const setVehicle = (data) => ({
  type: SET_VEHICLE,
  payload: data,
});

export const setUser = (data) => ({
  type: SET_USER,
  payload: data,
});

// async action creators
export const BASE_URL = `${URL}/api/v1`;

export function fetchToken({ username, password }) {
  return async (dispatch) => {
    dispatch(requestStart(FETCH_TOKEN));
    try {
      const token = await requestToken({ username, password });
      dispatch(setToken(token));
      return dispatch(requestEnd(FETCH_TOKEN));
    } catch (err) {
      return dispatch(requestError(err, FETCH_TOKEN));
    }
  };
}

export function changePassword({ token, oldpassword, newpassword }) {
  return asyncAction({
    method: "PUT",
    body: { old_password: oldpassword, new_password: newpassword },
    token,
    url: `${BASE_URL}/change-password/`,
    label: `${CHANGE_PASSWORD}`,
    startFunction: () => setPasswordChangeStatus("START"),
    successFunction: () => setPasswordChangeStatus("SUCCESS"),
    errorFunction: () => setPasswordChangeStatus("ERROR"),
  });
}

export function asyncAction({
  method,
  token,
  url,
  label,
  startFunction,
  setFunction,
  successFunction,
  errorFunction,
  body,
}) {
  return async (dispatch) => {
    startFunction ? dispatch(startFunction()) : dispatch(requestStart(label));
    let response;
    try {
      if (method === "POST") {
        response = await post({
          url,
          token,
          body: body,
        });
        dispatch(setPosted({ label, id: response.id }));
      } else if (method === "PUT") {
        response = await put({
          url,
          token,
          body: body,
        });
      } else if (method === "DELETE") {
        response = await deleteRequest({
          url,
          token,
        });
      } else {
        response = await get({
          url,
          token,
        });
      }
      if (response.error)
        throw `Bad request: ${response.error} @ ${response.route}`;
      setFunction && dispatch(setFunction(response));
      return successFunction
        ? dispatch(successFunction())
        : dispatch(requestEnd(label));
    } catch (err) {
      return errorFunction
        ? dispatch(errorFunction())
        : dispatch(requestError(err, label));
    }
  };
}

export function fetchVehicles({ last_updated, token }) {
  let params = `?last_updated=${last_updated || null}`;

  return asyncAction({
    token,
    url: `${BASE_URL}/vehicle/${params}`,
    label: FETCH_VEHICLES,
    setFunction: setVehicles,
  });
}

export const FETCH_USER_URL = `${BASE_URL}/employee/current/`;
export function fetchUser({ token }) {
  return asyncAction({
    token,
    url: FETCH_USER_URL,
    label: FETCH_USER,
    setFunction: setUser,
  });
}

export function fetchReports({ last_updated, token, url, vehicle, page_size }) {
  let params = `?vehicle=${vehicle || null}&last_updated=${
    last_updated || null
  }${page_size ? "&page_size=" + page_size : ""}`;

  return asyncAction({
    token,
    url: url || `${BASE_URL}/report/${params}`,
    label: FETCH_REPORTS,
    setFunction: setReports,
  });
}

export function fetchPendingReports({ last_updated, token, url }) {
  let params = `?page_size=250&pending=${true}&last_updated=${
    last_updated || null
  }`;

  return asyncAction({
    token,
    url: url || `${BASE_URL}/report/${params}`,
    label: FETCH_REPORTS_PENDING,
    setFunction: setReportsPending,
  });
}

export function postDefault({ token, data, type }) {
  return asyncAction({
    method: "POST",
    body: data,
    token,
    url: `${BASE_URL}/${type}/`,
    label: `${BASE_URL}/${type}/`,
  });
}

export function postReport({ token, report, myReportId }) {
  return asyncAction({
    method: "POST",
    body: report,
    token,
    url: `${BASE_URL}/report/`,
    label: `${POST_REPORT}/${report.vehicle}/${myReportId}`,
    setFunction: () =>
      deleteMyReport({ id: myReportId, vehicle: report.vehicle }),
  });
}

export function putReport({ token, report, myReportId }) {
  return asyncAction({
    method: "PUT",
    body: report,
    token,
    url: `${BASE_URL}/report/${myReportId}/`,
    label: `${PUT_REPORT}/${myReportId}`,
    setFunction: () =>
      deleteMyReport({ id: myReportId, vehicle: report.vehicle }),
  });
}

export function deleteReport({ token, id, vehicle }) {
  return asyncAction({
    method: "DELETE",
    token,
    url: `${BASE_URL}/report/${id}/`,
    label: `${DELETE_REPORT}/${id}`,
    setFunction: () => deleteApiReport({ id, vehicle }),
  });
}

export function postVehicle({ token, data, myId }) {
  return asyncAction({
    method: "POST",
    body: data,
    token,
    url: `${BASE_URL}/vehicle/`,
    label: `${POST_VEHICLE}/${myId}`,
    setFunction: () => deleteMyVehicle({ id: myId }),
  });
}

export function putVehicle({ token, data, myId }) {
  return asyncAction({
    method: "PUT",
    body: data,
    token,
    url: `${BASE_URL}/vehicle/${myId}/`,
    label: `${PUT_VEHICLE}/${myId}`,
    setFunction: () => deleteMyVehicle({ id: myId }),
  });
}

export function putVehicleWithoutMyVehicle({ token, data, id }) {
  return asyncAction({
    method: "PUT",
    body: data,
    token,
    url: `${BASE_URL}/vehicle/${id}/`,
    label: `${PUT_VEHICLE}/${id}`,
    setFunction: setVehicle,
  });
}

export function deleteVehicle({ token, id }) {
  return asyncAction({
    method: "DELETE",
    token,
    url: `${BASE_URL}/vehicle/${id}/`,
    label: `${DELETE_VEHICLE}/${id}`,
    setFunction: () => deleteMyVehicle({ id }),
  });
}

export function fetchActions({ token }) {
  return asyncAction({
    token,
    url: `${BASE_URL}/action/`,
    label: FETCH_ACTIONS,
    setFunction: setActions,
  });
}

export function fetchUsers({ token }) {
  return asyncAction({
    token,
    url: `${BASE_URL}/user/`,
    label: FETCH_USERS,
    setFunction: setUsers,
  });
}

export function fetchCategories({ token }) {
  return asyncAction({
    token,
    url: `${BASE_URL}/category/`,
    label: FETCH_CATEGORIES,
    setFunction: setCategories,
  });
}

export function fetchFaults({ token }) {
  return asyncAction({
    token,
    url: `${BASE_URL}/fault/`,
    label: FETCH_FAULTS,
    setFunction: setFaults,
  });
}

export function fetchReportTypes({ token }) {
  return asyncAction({
    token,
    url: `${BASE_URL}/reporttype/`,
    label: FETCH_REPORT_TYPES,
    setFunction: setReportTypes,
  });
}
