import React from "react";
import styles from "./styles.module.css";

const IconButton = ({ color, dataCy, icon, iconStyle, onClick }) => (
  <div onClick={onClick} className={styles.container} style={{ color: color }}>
    <i
      data-cy={dataCy}
      className={`material-icons${iconStyle ? `-${iconStyle}` : ""}`}
    >
      {icon || "face"}
    </i>
  </div>
);

export default IconButton;
