import defaultStates from "./default-states";
import { apiReportToAppReport } from "../services/api";
import { dateToYYYYMMDD } from "./date-utils";

export const convertObjectToArray = (obj) => {
  const array = [];
  Object.keys(obj).forEach((id) => array.push(obj[id]));
  return array;
};

export function mergeAllReports({ online = {}, offline = {} }) {
  const reports = {};
  Object.keys(online).map((vehicle) =>
    Object.keys(online[vehicle]).map((report) => {
      if (!reports[vehicle]) reports[vehicle] = {};
      reports[vehicle][report] = apiReportToAppReport(online[vehicle][report]);
    })
  );
  Object.keys(offline).map((vehicle) =>
    Object.keys(offline[vehicle]).map((report) => {
      if (!reports[vehicle]) reports[vehicle] = {};
      reports[vehicle][report] = offline[vehicle][report];
    })
  );

  return reports;
}

export function mergeReportsOfVehicle({ online = {}, offline = {} }) {
  const reports = {};
  Object.keys(online).map((reportId) => {
    reports[reportId] = apiReportToAppReport(online[reportId]);
  });

  Object.keys(offline).map((reportId) => {
    reports[reportId] = offline[reportId];
  });

  return reports;
}

export function allReportsToArray(all) {
  const reports = [];
  Object.keys(all).map((vehicleID) =>
    Object.keys(all[vehicleID]).map((reportID) =>
      reports.push(all[vehicleID][reportID])
    )
  );
  return reports;
}

export function mergedReportsToArray(reports, onlineOrder) {
  // reports is of shape {<id>: {<report>}}
  // onlineOrder is an array of report ID ints
  const reportArray = [];
  const clonedReports = { ...reports };
  onlineOrder.forEach((id) => {
    reportArray.push(clonedReports[id]);
    delete clonedReports[id];
  });
  const myReports = Object.keys(clonedReports).map((id) => clonedReports[id]);
  myReports.sort((a, b) => a.inspectionDate < b.inspectionDate);
  return myReports.concat(reportArray);
}

export function cleanVehicleInfo(vehicle) {
  const ignore = [
    "id",
    "deleted",
    "company",
    "last_updated",
    "last_six_weekly",
  ];
  const newVehicle = { ...vehicle };
  ignore.map((key) => delete newVehicle[key]);
  return vehicle ? newVehicle : vehicle;
}

export function capitalize(str) {
  let words = str.split(" ");
  let wordsCapitalized = words.map((w) => [
    `${w[0].toUpperCase()}${w.slice(1)}`,
  ]);
  return wordsCapitalized.join(" ");
}

export function getReportSectionKeyValuePairs(sectionState) {
  let subtitle = [];
  let thisSectionStateKeys = Object.keys(sectionState);

  thisSectionStateKeys.map((key) => {
    switch (key) {
      case "fault":
        if (sectionState[key].id) {
          subtitle.push({
            key: "Fault",
            value: sectionState[key].name,
          });
        } else {
          subtitle.push({ key: "Fault", value: "" });
        }
        break;
      case "action":
        if (sectionState[key].id) {
          subtitle.push({
            key: "Action",
            value: sectionState[key].name,
          });
        } else {
          subtitle.push({ key: "Action", value: "" });
        }
        break;
      case "inspectionDate":
        if (sectionState[key].date) {
          subtitle.push({
            key: "Inspection Date",
            value: new Date(sectionState[key].date).toDateString(),
          });
        } else {
          subtitle.push({ key: "Inspection Date", value: "" });
        }
        break;
      case "tachograph":
        if (sectionState[key].date) {
          subtitle.push({
            key: "Tachograph Date",
            value: new Date(sectionState[key].date).toDateString(),
          });
        } else {
          subtitle.push({ key: "Tachograph Date", value: "" });
        }
        break;

      case "reportType":
        if (sectionState[key].id) {
          subtitle.push({
            key: "Report Type",
            value: sectionState[key].name,
          });
        } else {
          subtitle.push({ key: "Report Type", value: "" });
        }
        break;

      case "odometer":
        if (sectionState[key].reading) {
          subtitle.push({
            key: "Odometer",
            value: `${sectionState[key].reading} ${sectionState[key].unit}`,
          });
        } else {
          subtitle.push({ key: "Odometer", value: "" });
        }
        break;
    }
  });
  return subtitle;
}

export function deleteRectification(pk, rectifications) {
  const keys = Object.keys(rectifications);
  let clone = { ...rectifications };
  if (pk.toString() === (keys.length - 1).toString()) {
    delete clone[pk];
  } else {
    for (let i = 0; i < keys.length; i++) {
      if (i === parseInt(pk)) {
        continue;
      } else if (i < parseInt(pk)) {
        clone = { ...clone, [i]: { ...rectifications[i] } };
      } else {
        clone = { ...clone, [i - 1]: { ...rectifications[i] } };
      }
    }
    delete clone[keys.length - 1];
  }
  return clone;
}

export function deletePart(index, parts) {
  const clone = [...parts];
  clone.splice(index, 1);
  return clone;
}

export function generateNextReportId(reports) {
  if (Object.keys(reports).length > 0) {
    return parseInt(Object.keys(reports).reduce((a, b) => (a > b ? a : b))) + 1;
  } else {
    return 0;
  }
}

export function generateNextReportIdNeg(reports) {
  if (Object.keys(reports).length > 0) {
    let id =
      parseInt(
        Object.keys(reports).reduce((a, b) =>
          parseInt(a) < parseInt(b) ? a : b
        )
      ) - 1;
    return id < 0 ? id : -1;
  } else {
    return -1;
  }
}

export function generateNextIdNeg(items) {
  if (Object.keys(items).length == 0) return -1;
  const id =
    parseInt(
      Object.keys(items).reduce((a, b) => (parseInt(a) < parseInt(b) ? a : b))
    ) - 1;
  return id < 0 ? id : -1;
}

export function compareReportInfo(a, b) {
  const data = [
    { a: a.reportType.name, b: b.reportType.name },
    {
      a: a.inspectionDate.date,
      b: b.inspectionDate.date,
    },
  ];

  return data.some((obj) => obj.a !== obj.b);
}

export function compareVehicleInfo(a, b) {
  const data = [
    { a: a.odometer.reading, b: b.odometer.reading },
    {
      a: dateToYYYYMMDD(a.tachograph.date),
      b: dateToYYYYMMDD(b.tachograph.date),
    },
  ];

  return data.some((obj) => obj.a !== obj.b);
}

export function reportNotEqualToCurrent(
  report,
  current,
  defaultTachographNextDate
) {
  // if new report
  if (!current) {
    current = {
      reportInfo: defaultStates.REPORT_INFO,
      vehicleInfo: {
        ...defaultStates.VEHICLE_INFO,
        tachograph: {
          ...defaultStates.VEHICLE_INFO.tachograph,
          date: defaultTachographNextDate,
        },
      },
    };
  }
  return (
    compareVehicleInfo(report.vehicleInfo, current.vehicleInfo) ||
    compareReportInfo(report.reportInfo, current.reportInfo)
  );
}

export const getStyleName = (label) =>
  label.replace(/[^a-zA-Z]/g, "-").toLowerCase();

export const cleanFaultName = (name) => name.replace("BRAKES - ", "");

export const getInitials = (user) => {
  if (user?.first_name?.length > 0 && user?.last_name?.length > 0) {
    return `${user.first_name[0]}. ${user.last_name[0]}`;
  } else {
    return "";
  }
};

export const getLowestKeyOfObject = (obj) =>
  Object.keys(obj).reduce((a, b) => (parseInt(a) < parseInt(b) ? a : b), -1);
