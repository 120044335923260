import React from "react";
import { connect } from "react-redux";
import { ListItemText, Typography } from "@material-ui/core";

import styles from "./styles.module.css";
import { selectVehicleRegById } from "common/ducks/api";
import { selectMyVehicleRegById } from "common/ducks/vehicle";
import { dateToDDMMYYYY } from "common/modules/date-utils";

const ReportSearchResult = ({ color, report, vehicle, vehicleReg }) => (
  <div
    className={styles.container}
    style={{ color: color }}
    data-cy={
      vehicle
        ? `${vehicleReg}${report.reportInfo?.reportType?.name}${dateToDDMMYYYY(
            report.reportInfo?.inspectionDate?.date,
            ""
          )}`
        : `${report.reportInfo?.reportType?.name}${dateToDDMMYYYY(
            report.reportInfo?.inspectionDate?.date,
            ""
          )}`
    }
  >
    <ListItemText
      primary={`${vehicle ? vehicleReg + " - " : ""}${
        report.reportInfo &&
        report.reportInfo.reportType &&
        report.reportInfo.reportType.name
      }`}
      secondary={
        <React.Fragment>
          <Typography
            component="span"
            variant="body2"
            className={styles.inline}
            color="textPrimary"
          >
            {`Inspection Date: `}
          </Typography>
          {report.reportInfo &&
            report.reportInfo.inspectionDate &&
            new Date(report.reportInfo.inspectionDate.date).toDateString()}
        </React.Fragment>
      }
    />
  </div>
);

const mapStateToProps = (state, ownProps) => {
  let vehicleReg = null;
  if (ownProps.vehicle) {
    if (ownProps.report.vehicleInfo.vehicle < 0) {
      vehicleReg = selectMyVehicleRegById(
        state,
        ownProps.report.vehicleInfo.vehicle
      );
    } else {
      vehicleReg = selectVehicleRegById(
        state,
        ownProps.report.vehicleInfo.vehicle
      );
    }
  }
  return {
    vehicleReg: vehicleReg,
  };
};

export default connect(mapStateToProps)(ReportSearchResult);
