import * as React from "react";
import styles from "./styles.module.css";

import { ListItem } from "@material-ui/core";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { Link } from "react-router-dom";
import RectificationSearchResult from "../rectificationsearchresult";

const ListItemLink = (props) => {
  return <ListItem divider button component={Link} {...props} />;
};

class RectificationsSearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDialogOpen: false, reportRightClicked: null };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 60,
    });
  }

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  RenderRow = ({ index, style, key, parent }) => {
    const rectification = this.props.rectifications[index];
    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <ListItemLink
          key={rectification.id}
          to={`/reports/${rectification.vehicle}/${rectification.report}`}
          style={style}
        >
          <RectificationSearchResult
            vehicle={this.props.vehicle}
            rectification={rectification}
          />
        </ListItemLink>
      </CellMeasurer>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={this.cache}
              rowHeight={this.cache.rowHeight}
              rowRenderer={this.RenderRow}
              rowCount={this.props.rectifications.length}
              overscanRowCount={3}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default RectificationsSearchResults;
