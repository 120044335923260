import React from "react";
import styles from "./styles.module.css";
import { connect } from "react-redux";

import {
  selectWarrantiesByVehicle,
  selectActiveWarrantiesByVehicle,
} from "common/ducks/api";

import { MyTheme } from "common/modules/colors";
import SearchField from "../searchfield";
import { searchWarrantiesFilter } from "common/modules/search-filters";
import WarrantiesSearchResults from "../warrantiessearchresults";
import { LinearProgress } from "@material-ui/core";
const colors = MyTheme.colors;

class Warranties extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      filtered: [],
    };
  }

  componentDidMount() {
    this.SearchFilterFunction("");
  }

  componentDidUpdate(prevProps) {
    if (this.props.warranties !== prevProps.warranties) {
      this.SearchFilterFunction(this.state.searchInput);
    }
  }

  SearchFilterFunction = (input) => {
    let filtered = searchWarrantiesFilter(this.props.warranties, input);

    this.setState({
      filtered: filtered,
      searchInput: input,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <SearchField
          label="Warranties"
          value={this.state.searchInput}
          onChange={(e) => this.SearchFilterFunction(e.target.value)}
        />
        {this.props.isFetching && <LinearProgress />}
        {this.state.filtered.length > 0 ? (
          <WarrantiesSearchResults warranties={this.state.filtered} />
        ) : null}
      </div>
    );
  }
}

export default Warranties;
