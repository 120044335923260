import React from "react";
import styles from "./styles.module.css";
import IconLink from "../iconlink";
import { MyTheme } from "common/modules/colors";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";
const TopNav = ({ children, title, backTo }) => (
  <AppBar
    position="static"
    style={{ backgroundColor: MyTheme.colors.primaryLighter }}
  >
    <Toolbar style={{ paddingLeft: 0 }}>
      <IconLink
        dataCy="back"
        edge="start"
        icon="arrow_back"
        color={MyTheme.colors.onPrimary}
        link={backTo || "/vehicles"}
      />
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        {title}
      </Typography>
      {children}
    </Toolbar>
  </AppBar>
);

export default TopNav;
