import React from "react";
import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const colours = MyTheme.colors;

export default function LogIn({ errors, logIn }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleLogIn = (e) => {
    e.preventDefault();
    logIn({ username: username, password: password });
  };

  return (
    <div className={styles.container}>
      <Card className={styles.formcontainer}>
        <div
          className={styles.logocontainer}
          style={{ backgroundColor: colours.primary }}
        >
          <CardMedia
            image={process.env.PUBLIC_URL + "/images/logo-name-white.svg"}
            title="FleetViper"
            style={{
              height: "4em",
              backgroundSize: "11em",
            }}
          />
        </div>
        <ul data-cy="loginerrors" className=".error-messages">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
        <form
          className={styles.form}
          onSubmit={handleLogIn}
          data-cy="loginform"
        >
          <TextField
            label="Username"
            variant="outlined"
            id="username"
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            data-cy="username"
          />

          <TextField
            label="Password"
            variant="outlined"
            id="password"
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            data-cy="password"
          />
          <CardActions className={styles.actions}>
            <Button
              className={styles.submit}
              variant="contained"
              size="large"
              color="primary"
              id="login"
              type="submit"
              data-cy="login"
            >
              Log In
            </Button>
          </CardActions>
        </form>
        {process.env.REACT_APP_FEATURE_CHANGE_PASSWORD && (
          <Link to="/passwordreset" className={styles.reset}>
            Reset password
          </Link>
        )}
      </Card>
    </div>
  );
}
