import React, { useEffect } from "react";
import styles from "./styles.module.css";
import {
  Chip,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getISOWeek } from "date-fns";

import { MyTheme } from "common/modules/colors";
import {
  fetchVehicles,
  selectToken,
  selectVehiclesDownloaded,
  selectVehiclesSchedule,
} from "common/ducks/api";
import { dateToDDMMYYYY, hasBeenOverSixWeeks } from "common/modules/date-utils";

const colors = MyTheme.colors;

const Schedule = (props) => {
  useEffect(() => {
    const fetchData = async () => {
      await props.getVehicles({
        token: props.token,
        last_updated: props.vehiclesDownloaded,
      });
    };

    fetchData();
  }, []);

  const seenVehicles = {};

  return (
    <div className={styles.container} data-cy="schedule-list">
      <List
        style={{
          position: "relative",
          overflow: "auto",
        }}
        subheader={<li />}
      >
        {Object.keys(props.vehicles).map((yyyyMMDD) => {
          const weekData = props.vehicles[yyyyMMDD];
          return (
            <li key={yyyyMMDD} className={styles.list}>
              <ul>
                <ListSubheader style={{ backgroundColor: colors.cardLighter }}>
                  ISO Week {getISOWeek(new Date(yyyyMMDD))} - starting{" "}
                  {dateToDDMMYYYY(yyyyMMDD)}:
                </ListSubheader>
                {weekData.map((vehicle) => {
                  const isOverdue =
                    hasBeenOverSixWeeks(
                      vehicle.last_six_weekly?.inspection_date
                    ) && !seenVehicles.hasOwnProperty(vehicle.reg);

                  if (isOverdue) {
                    seenVehicles[vehicle.reg] = true;
                  }

                  return (
                    <ListItem
                      key={vehicle.reg}
                      button={true}
                      divider={true}
                      component={Link}
                      to={{
                        pathname: `/vehicles/${vehicle.id}`,
                        state: { back: "/schedule" },
                      }}
                      data-cy={`vehicle-${vehicle.reg}`}
                    >
                      <ListItemText primary={`${vehicle.reg}`} />
                      {isOverdue && (
                        <Chip label="Overdue" size="small" color="secondary" />
                      )}
                    </ListItem>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </List>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: selectToken(state),
    vehicles: selectVehiclesSchedule(state),
    vehiclesDownloaded: selectVehiclesDownloaded(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicles: async ({ token, last_updated }) => {
      await dispatch(fetchVehicles({ token, last_updated }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
