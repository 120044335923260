const H = 0;
const S = 1;
const L = 2;

// const primary = [187, 100, 19]; // Green
const primary = [205, 74, 41]; // Android blue calendar
// const primary = [231, 48, 48]; // Windows blue calendar
const secondary = [48, 100, 82];
const positive = [40, 100, 50];
const action = [133, 33, 33];
const position = [234, 32, 31];
const fault = [0, 21, 47];
const warranty = [34, 80, 39];
const negative = [0, 100, 50];
const confirm = [129, 56, 74];
const background = [0, 0, 50];
const card = [primary[H], primary[S], 97];
const text = [0, 0, 28];
const onPrimary = [255, 255, 255];

export const primaryColorVariant = "#3700B3";
export const secondaryColor = "#03DAC6";
export const secondaryColorVariant = "#018786";
export const backgroundColor = "black";
export const surface = "black";
export const error = "#B00020";
export const onSecondary = "#000000";
export const onBackground = "#000000";
export const onSurface = "#000000";
export const onError = "#FFFFFF";

export const MyTheme = {
  dark: true,
  colors: {
    primary: `hsl(${primary[H]},${primary[S]}%,${primary[L]}%)`,
    onPrimary: `rgb(255,255,255)`,
    onPrimarySubtle: `rgba(${onPrimary[H]},${onPrimary[S]},${onPrimary[L]},0.8)`,
    onPrimarySubtler: `rgba(${onPrimary[H]},${onPrimary[S]},${onPrimary[L]},0.6)`,
    onPrimarySubtlest: `rgba(${onPrimary[H]},${onPrimary[S]},${onPrimary[L]},0.4)`,
    primaryLighter: `hsl(${primary[H]},${primary[S]}%,${primary[L] + 7}%)`,
    primaryDarker: `hsl(${primary[H]},${primary[S]}%,${primary[L] - 7}%)`,
    background: `hsl(${background[H]},${background[S]}%,${background[L]}%)`,
    card: `hsl(${card[H]},${card[S]}%,${card[L]}%)`,
    cardLighter: `hsl(${card[H]},${card[S]}%,${card[L] + 20}%)`,
    cardDarker: `hsl(${card[H]},${card[S]}%,${card[L] - 10}%)`,
    text: `hsl(${text[H]},${text[S]}%,${text[L]}%)`,
    textAction: `hsl(${action[H]},${action[S]}%,${action[L]}%)`,
    textFault: `hsl(${fault[H]},${fault[S]}%,${fault[L]}%)`,
    textWarranty: `hsl(${warranty[H]},${warranty[S]}%,${warranty[L]}%)`,
    textLighter: `hsl(${text[H]},${text[S]}%,${text[L] + 20}%)`,
    border: "rgb(199, 199, 204)",
    error: "rgb(245,127,126)",
    warning: "rgb(255,237,164)",
    confirm: `hsl(${confirm[H]},${confirm[S]}%,${confirm[L]}%)`,
    negative: `hsl(${negative[H]},${negative[S]}%,${negative[L]}%)`,
    positive: `hsl(${positive[H]},${positive[S]}%,${positive[L]}%)`,
    negativeLighter: `hsl(${negative[H]},${negative[S] - 50}%,${
      negative[L] + 40
    }%)`,
    positiveLighter: `hsl(${positive[H]},${positive[S] - 50}%,${
      positive[L] + 40
    }%)`,

    secondary: `hsl(${secondary[H]},${secondary[S]}%,${secondary[L]}%)`,
    secondaryDarker: `hsl(${secondary[H]},${secondary[S]}%,${
      secondary[L] + 20
    }%)`,
    secondaryDark: `hsl(${secondary[H]},${secondary[S]}%,${
      secondary[L] - 35
    }%)`,
    secondaryCardLight: `hsl(${secondary[H]},${secondary[S]}%,${
      secondary[L] + 10
    }%)`,
    secondaryCardLightest: `hsl(${secondary[H]},${secondary[S]}%,${
      secondary[L] + 15
    }%)`,
    onSecondary: "rgb(80,80,80)",
  },
};
