import React from "react";
import styles from "./styles.module.css";
import { withRouter } from "react-router-dom";

import Card from "@material-ui/core/Card";
import { Button, CardHeader, LinearProgress } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";

import IconButtonHorizontal from "../iconbuttonhorizontal";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CardContent from "@material-ui/core/CardContent";

import { connect } from "react-redux";
import ReactToPrint from "react-to-print";

import {
  fetchReports,
  postVehicle,
  putVehicle,
  selectActiveWarrantiesByVehicle,
  selectFetchingReports,
  selectLastUpdated,
  selectRectificationsByVehicle,
  selectReportsByVehicle,
  selectReportsOrderByVehicle,
  selectToken,
  selectVehicleById,
  selectWarrantiesByVehicle,
} from "common/ducks/api";
import TopNav from "../topnav";
import History from "../history";
import Warranties from "../warranties";
import { Redirect } from "react-router-dom";
import VehicleInspect from "../inspectvehicle";
import {
  selectCurrentVehicle,
  cancelVehicle,
  openVehicle,
  saveVehicle,
  deleteMyVehicle,
  duplicateVehicle,
  createVehicle,
} from "common/ducks/vehicle";
import {
  apiVehicleToAppVehicle,
  reportToInspectionForm,
  myVehicleToApiPayload,
} from "common/services/api";
import {
  cleanVehicleInfo,
  mergedReportsToArray,
  mergeReportsOfVehicle,
} from "common/modules/utils";
import defaultStates from "common/modules/default-states";

import { MyTheme } from "common/modules/colors";
import RectificationsSearch from "../rectificationssearch";
import Printable from "../printable";
import InspectionForm from "../inspectionform";
import InputDate from "../inputdate";

import { selectMyReportsByVehicle } from "common/ducks/report";
import { isWithinDateRange } from "common/modules/date-utils";
const colors = MyTheme.colors;

class Vehicle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInspecting: true,
      editing: {},
      info: {},
      activeWarranties: true,
      historyFromDate: null,
      historyToDate: null,
      history: [],
      backUrl: null,
    };
  }

  isEditing = () => {
    return Object.keys(this.state.editing).some(
      (key) => this.state.editing[key]
    );
  };

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      backUrl: location.state?.back,
    });

    if (this.props.vehicleID === "new") {
      this.props.createVehicle({
        vehicle: this.props.vehicleID,
      });
      this.setState({ info: { ...defaultStates.VEHICLE } });
    } else if (this.props.vehicleID < 0) {
      this.props.openVehicle({
        id: this.props.vehicleID,
      });
      this.setState({ info: { ...defaultStates.VEHICLE } });
    } else {
      this.props.duplicateVehicle({
        id: this.props.vehicleID,
        vehicle: this.props.vehicle,
      });
      this.setState({
        info: apiVehicleToAppVehicle(this.props.vehicle),
      });
    }
    this.props.getReports({
      token: this.props.token,
      vehicle: this.props.vehicleID,
      last_updated: this.props.last_updated,
    });
  }

  componentWillUnmount() {
    if (this.props.current_vehicle) {
      this.cancel();
    }
  }

  save = async () => {
    this.props.saveVehicle({
      vehicle: this.state.info,
      id: this.props.current_vehicle.id,
    });

    if (this.props.current_vehicle.id >= 0) {
      await this.props.putVehicle({
        token: this.props.token,
        data: myVehicleToApiPayload({
          vehicle: this.state.info,
        }),
        myId: this.props.current_vehicle.id,
      });
    } else {
      await this.props.postVehicle({
        token: this.props.token,
        data: myVehicleToApiPayload({
          vehicle: this.state.info,
        }),
        myId: this.props.current_vehicle.id,
      });
    }
    this.setState({ isInspecting: false });
  };

  cancel = () => {
    this.props.cancelVehicle({
      id: this.props.current_vehicle.id,
    });
    this.setState({ isInspecting: false });
  };

  render() {
    if (this.props.current_vehicle && !this.props.fetchingReports) {
      return (
        <div className={styles.container}>
          <TopNav
            title={
              this.props.current_vehicle?.data
                ? this.props.current_vehicle.data.reg
                : "New vehicle"
            }
            backTo={this.state.backUrl}
          >
            {this.props.current_vehicle?.data && (
              <div style={{ flexShrink: 1 }} data-cy="create-report">
                <IconButtonHorizontal
                  title="Create Report"
                  icon="add"
                  iconStyle="outlined"
                  color={MyTheme.colors.onPrimary}
                  link={`/reports/${this.props.current_vehicle.id}/new`}
                />
              </div>
            )}
          </TopNav>
          <div className={styles.cardscontainer}>
            <div className={styles.vehicleInfo}>
              <Section
                title="Info"
                action={
                  this.isEditing() && (
                    <div>
                      <Button
                        data-cy="cancel"
                        color="primary"
                        onClick={this.cancel}
                      >
                        CANCEL
                      </Button>
                      <Button
                        data-cy="save"
                        color="primary"
                        onClick={this.save}
                      >
                        SAVE
                      </Button>
                    </div>
                  )
                }
              >
                <VehicleInspect
                  onCancel={this.cancel}
                  onSave={this.save}
                  editing={this.state.editing}
                  setEditing={(data) => this.setState({ editing: data })}
                  info={this.state.info}
                  setInfo={(data) => this.setState({ info: data })}
                  vehicle={cleanVehicleInfo(this.props.current_vehicle.data)}
                />
              </Section>
            </div>

            {this.props.current_vehicle?.data && (
              <div className={styles.past}>
                <Section
                  title="History"
                  action={
                    <FormControlLabel
                      control={
                        <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                              <Button color="primary">
                                <PrintIcon />
                              </Button>
                            );
                          }}
                          content={() => this.componentRef}
                        />
                      }
                    />
                  }
                >
                  <Printable ref={(el) => (this.componentRef = el)}>
                    <div>
                      {this.props.reports
                        .filter(
                          (report) =>
                            // Check for recently deleted report returning undefined
                            report &&
                            isWithinDateRange({
                              date: report.reportInfo.inspectionDate.date,
                              from: this.state.historyFromDate,
                              to: this.state.historyToDate,
                            })
                        )
                        .map((report, index) => (
                          <InspectionForm
                            key={index}
                            report={{
                              ...reportToInspectionForm({ report }),
                            }}
                            vehicle={this.props.vehicle}
                          />
                        ))}
                    </div>
                  </Printable>
                  <div className={styles.historydaterange}>
                    <InputDate
                      clearable={true}
                      dataCy="history-from-date"
                      label="From"
                      value={this.state.historyFromDate}
                      onChange={(value) => {
                        this.setState({ historyFromDate: value });
                      }}
                    />
                    <InputDate
                      clearable={true}
                      dataCy="history-to-date"
                      label="To"
                      value={this.state.historyToDate}
                      onChange={(value) => {
                        this.setState({ historyToDate: value });
                      }}
                    />
                  </div>
                  <History
                    vehicleID={this.props.vehicleID}
                    isFetching={this.props.fetchingReports}
                    reports={this.props.reports.filter(
                      (report) =>
                        report &&
                        isWithinDateRange({
                          date: report.reportInfo.inspectionDate.date,
                          from: this.state.historyFromDate,
                          to: this.state.historyToDate,
                        })
                    )}
                  />
                </Section>

                <Section
                  title="Parts fitted"
                  action={
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.activeWarranties}
                          onChange={() =>
                            this.setState((prev) => ({
                              activeWarranties: !prev.activeWarranties,
                            }))
                          }
                          color="primary"
                          name="In warranty"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="In warranty"
                    />
                  }
                >
                  <Warranties
                    warranties={
                      this.state.activeWarranties
                        ? this.props.activeWarranties
                        : this.props.warranties
                    }
                    isFetching={this.props.fetchingReports}
                  />
                </Section>
                <Section title="Rectifications">
                  <RectificationsSearch
                    vehicleID={this.props.vehicleID}
                    isFetching={this.props.fetchingReports}
                  />
                </Section>
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.state.isInspecting) {
      return <LinearProgress />;
    } else {
      return <Redirect to="/vehicles" />;
    }
  }
}

const Section = ({ action, children, title }) => (
  <Card
    raised={true}
    style={{ backgroundColor: colors.card, width: "100%" }}
    className={styles.card}
  >
    <CardHeader title={title} action={action} />
    <CardContent>{children}</CardContent>
  </Card>
);

const mapDispatchToProps = (dispatch) => {
  return {
    cancelVehicle: ({ id }) => {
      dispatch(cancelVehicle({ id }));
    },
    saveVehicle: ({ id, vehicle }) => {
      dispatch(saveVehicle({ id, vehicle }));
    },
    openVehicle: ({ id }) => {
      dispatch(openVehicle({ id }));
    },
    createVehicle: () => {
      dispatch(createVehicle());
    },
    deleteVehicle: ({ id }) => {
      dispatch(deleteMyVehicle({ id }));
    },
    duplicateVehicle: ({ id, vehicle }) => {
      dispatch(duplicateVehicle({ id, vehicle }));
    },
    putVehicle: async ({ token, data, myId }) => {
      await dispatch(putVehicle({ token, data, myId }));
    },
    postVehicle: async ({ token, data, myId }) => {
      await dispatch(postVehicle({ token, data, myId }));
    },
    getReports: async ({ token, last_updated, vehicle }) => {
      if (vehicle !== "new") {
        await dispatch(
          fetchReports({ token, last_updated, vehicle, page_size: 1000 })
        );
      }
    },
  };
};
const mapStateToProps = (state, ownProps) => {
  // If duplicating vehicle from API server
  let vehicle;
  if (ownProps.vehicleID >= 0) {
    vehicle = selectVehicleById(state, ownProps.vehicleID);
  } else {
    vehicle = null;
  }

  return {
    current_vehicle: selectCurrentVehicle(state),
    token: selectToken(state),
    vehicle: vehicle,
    rectifications: selectRectificationsByVehicle(state, vehicle?.id),
    warranties: selectWarrantiesByVehicle(state, vehicle?.id),
    activeWarranties: selectActiveWarrantiesByVehicle(state, vehicle?.id),
    last_updated: selectLastUpdated(state, ownProps.vehicleID),
    fetchingReports: selectFetchingReports(state),
    reports: mergedReportsToArray(
      mergeReportsOfVehicle({
        online: selectReportsByVehicle(state, ownProps.vehicleID),

        offline: selectMyReportsByVehicle(state, ownProps.vehicleID),
      }),
      selectReportsOrderByVehicle(state, ownProps.vehicleID)
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Vehicle)
);
