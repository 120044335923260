export function dateToYYYYMMDD(date) {
  date = date ? new Date(date) : new Date();
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  return `${year}-${month.length < 2 ? "0" + month : month}-${
    day.length < 2 ? "0" + day : day
  }`;
}

export function dateToDDMMYYYY(date, separator = "-") {
  date = date ? new Date(date) : new Date();
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  return `${day.length < 2 ? "0" + day : day}${separator}${
    month.length < 2 ? "0" + month : month
  }${separator}${year}`;
}

export function isFutureDate(input) {
  const now = dateToYYYYMMDD();
  const inputDate = dateToYYYYMMDD(input);
  return new Date(now) <= new Date(inputDate);
}

export function isWithinDateRange({ date, from, to }) {
  date = dateToYYYYMMDD(date);
  from = from && dateToYYYYMMDD(from);
  to = to && dateToYYYYMMDD(to);

  return (date >= from || from === null) && (date <= to || to === null);
}

export function weeksBetween(from, to) {
  return Math.floor((to - from) / (1000 * 60 * 60 * 24 * 7));
}

export function hasBeenSixWeeks(from, to = new Date()) {
  /** If from is null, it will default to epoch 1970 */
  const fromDate = new Date(from);
  return weeksBetween(fromDate, to) >= 6;
}

export function hasBeenOverSixWeeks(from, to = new Date()) {
  /** If from is null, it will default to epoch 1970 */
  const fromDate = new Date(from);
  return weeksBetween(fromDate, to) > 6;
}

export function datePlusWeeks(numWeeks, to = new Date()) {
  const date = new Date(to);
  date.setDate(date.getDate() + numWeeks * 7);
  return date;
}
