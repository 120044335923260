import React from "react";
import { ListItemText, Typography } from "@material-ui/core";

import styles from "./styles.module.css";

import { isFutureDate } from "common/modules/date-utils";

const WarrantySearchResult = ({ color, warranty, vehicle, vehicleReg }) => (
  <div className={styles.container} style={{ color: color }}>
    <ListItemText
      primary={warranty.description}
      secondary={
        <React.Fragment>
          <Typography
            component="span"
            variant="body2"
            className={styles.inline}
            color="textPrimary"
          >
            {isFutureDate(warranty.warranty_expiry)
              ? "Expires on: "
              : "Expired on: "}
          </Typography>
          {new Date(warranty.warranty_expiry).toDateString()}
        </React.Fragment>
      }
    />
  </div>
);

export default WarrantySearchResult;
