import * as React from "react";

import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import Card from "@material-ui/core/Card";
import { Button, CardHeader } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

import TextInput from "../textinput";
const colors = MyTheme.colors;

const Parts = ({ current, deletePart, handleChange, parts, setFunction }) => {
  return Object.keys(parts).map((id) => (
    <Card
      key={parts[id].id}
      className={styles.card}
      variant="outlined"
      style={{ backgroundColor: colors.secondaryCardLight }}
    >
      <CardHeader
        subheader={`Part ${parseInt(id) + 1}`}
        action={
          <Button
            style={{ color: MyTheme.colors.error }}
            onClick={() => {
              deletePart(id);
            }}
          >
            Delete
          </Button>
        }
      />
      <CardContent>
        <Part
          current={current && current[id]}
          handleChange={handleChange}
          index={id}
          part={parts[id]}
          setFunction={(part) =>
            setFunction([
              ...parts.slice(0, id),
              { ...part },
              ...parts.slice(id + 1),
            ])
          }
        />
      </CardContent>
    </Card>
  ));
};

const Part = ({ current, handleChange, index, part, setFunction }) => {
  return (
    <div className={styles.container} data-cy={`part${index}`}>
      <div>
        <TextInput
          dataCy="part_number"
          label="Part Number"
          defaultValue={part?.part_number || ""}
          onBlur={(e) => {
            let isEditing = e.target.value !== current?.part_number;
            handleChange(
              `part${part.id}.part_number`,
              () => {
                setFunction({
                  ...part,
                  part_number: e.target.value,
                });
              },
              isEditing
            );
          }}
        />
      </div>
      <div>
        <TextInput
          dataCy="description"
          label="Description"
          defaultValue={part?.description || ""}
          onBlur={(e) => {
            let isEditing = e.target.value !== current?.description;
            handleChange(
              `part${part.id}.description`,
              () => {
                setFunction({
                  ...part,
                  description: e.target.value,
                });
              },
              isEditing
            );
          }}
        />
      </div>
      <div className={styles.numbersContainer}>
        <div>
          <TextInput
            dataCy="nett"
            label="NETT (£)"
            type="number"
            defaultValue={part?.nett || ""}
            onBlur={(e) => {
              let isEditing = e.target.value !== current?.nett;
              handleChange(
                `part${part.id}.nett`,
                () => {
                  setFunction({
                    ...part,
                    nett: e.target.value,
                  });
                },
                isEditing
              );
            }}
          />
        </div>
        <div>
          <TextInput
            dataCy="quantity"
            label="Quantity"
            type="number"
            defaultValue={part?.quantity || ""}
            onBlur={(e) => {
              let isEditing = e.target.value !== current?.quantity;
              handleChange(
                `part${part.id}.quantity`,
                () => {
                  setFunction({
                    ...part,
                    quantity: e.target.value,
                  });
                },
                isEditing
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Parts;
