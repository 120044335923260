import * as React from "react";
import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import { ListItem, ListItemText } from "@material-ui/core";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { Link, useRouteMatch } from "react-router-dom";
import ReportSearchResult from "../reportsearchresult";
import WarrantySearchResult from "../warrantysearchresult";
import AlertDialog from "../dialog";

const ListItemLink = (props) => {
  return <ListItem divider button component={Link} {...props} />;
};

class WarrantiesSearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.height = this.itemSize * this.itemCount;
    this.state = { isDialogOpen: false, reportRightClicked: null };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 60,
    });
  }

  handleRightClick = (e, reportIndex) => {
    this.setState({ isDialogOpen: true, reportRightClicked: reportIndex });
    e.preventDefault();
    return false;
  };

  RenderRow = ({ index, style, key, parent }) => {
    const warranty = this.props.warranties[index];
    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <ListItemLink
          key={warranty.id}
          to={`/reports/${this.props.warranties[index].vehicle}/${this.props.warranties[index].report}`}
          style={style}
        >
          <WarrantySearchResult warranty={warranty} />
        </ListItemLink>
      </CellMeasurer>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={this.cache}
              rowHeight={this.cache.rowHeight}
              rowRenderer={this.RenderRow}
              rowCount={this.props.warranties.length}
              overscanRowCount={3}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default WarrantiesSearchResults;
