export function faultSearchFilter(arr, input) {
  const filtered = arr.filter((item) => {
    return `${item.name} ${item.im_no} ${item.category}`
      .toUpperCase()
      .includes(input.toUpperCase());
  });
  return filtered;
}

export function userSearchFilter(arr, input) {
  const filtered = arr.filter((item) => {
    return `${item.first_name}${item.last_name}}`
      .toUpperCase()
      .includes(input.toUpperCase());
  });
  return filtered;
}

export function actionSearchFilter(arr, input) {
  const filtered = arr.filter((item) => {
    return `${item.name}}`.toUpperCase().includes(input.toUpperCase());
  });
  return filtered;
}

export function vehicleSearchFilter(obj, input) {
  const filtered = Object.keys(obj).filter(function (key) {
    const itemData = obj[key].reg
      ? obj[key].reg.toUpperCase()
      : "".toUpperCase();
    const textData = input.toUpperCase();
    return itemData.indexOf(textData) > -1;
  });
  return filtered;
}

export function vehicleArraySearchFilter(arr, input) {
  const filtered = arr.filter(function (item) {
    const itemData = item.reg ? item.reg.toUpperCase() : "".toUpperCase();
    const textData = input.toUpperCase();
    return itemData.indexOf(textData) > -1;
  });
  return filtered;
}

export function reportSearchFilter(reports, input) {
  if (Array.isArray(reports)) {
    return reportArraySearchFilter(reports, input);
  } else {
    return reportObjectSearchFilter(reports, input);
  }
}

function reportArraySearchFilter(reports, input) {
  return reports.filter((item) => {
    // On delete, item could be null
    if (item) {
      return (
        _searchRectifications(item.rectifications, input) ||
        searchReportType(item.reportInfo?.reportType, input)
      );
    }
  });
}

function reportObjectSearchFilter(reports, input) {
  return Object.keys(reports).filter((id) => {
    let item = reports[id];
    return (
      _searchRectifications(item.rectifications, input) ||
      searchReportType(item.reportInfo?.reportType, input)
    );
  });
}

function compareActions(action, other) {
  return !action || other.id === action?.id;
}

function searchReportType(reportType, input) {
  return (
    !reportType || reportType.name.toUpperCase().includes(input.toUpperCase())
  );
}

function searchRectification(rectification, input) {
  const itemToSearch = `${rectification.fault.name}${rectification.description}`;
  return itemToSearch.toUpperCase().includes(input.toUpperCase());
}

function searchWarranty(warranty, input) {
  const itemToSearch = `${warranty.description}`;
  return itemToSearch.toUpperCase().includes(input.toUpperCase());
}

export function searchWarrantiesFilter(warranties, input) {
  return warranties.filter((warranty) => searchWarranty(warranty, input));
}

export function searchRectificationsFilter(rectifications, input, action) {
  return rectifications.filter(
    (rectification) =>
      compareActions(action, rectification.action) &&
      searchRectification(rectification, input)
  );
}

function _searchRectifications(rectifications, input) {
  const rectificationIDs = Object.keys(rectifications);

  if (rectificationIDs.length === 0 && input === "") return true;

  const rectificationfound = rectificationIDs.some((rectificationID) => {
    const rectification = rectifications[rectificationID];
    const rectificationData = `${rectification.action.name} ${rectification.fault.name} ${rectification.fault.im_no}`;
    return rectificationData.toUpperCase().includes(input.toUpperCase());
  });
  return rectificationfound;
}

export function reportObjSearchFilter(obj, input) {
  const reports = [];
  Object.keys(obj).map((id) => {
    let item = obj[id];
    let rectificationfound;

    //Check rectifications
    const rectificationIDs = Object.keys(item.rectifications);
    if (rectificationIDs.length === 0 && input === "") {
      reports.push(item);
    } else {
      rectificationfound = rectificationIDs.some((rectificationID) => {
        const rectification = item.rectifications[rectificationID];
        const rectificationData = `${rectification.action.name} ${rectification.fault.name} ${rectification.fault.im_no}`;
        return rectificationData.toUpperCase().includes(input.toUpperCase());
      });
      if (rectificationfound) {
        reports.push(item);
      }
    }
  });
  return reports;
}

export function allReportsSearchFilter(obj, input) {
  let reports = [];
  const vehicleIDs = Object.keys(obj);
  vehicleIDs.map((vehicleID) => {
    let vehicleReports = obj[vehicleID];
    reports = [...reports, ...reportObjSearchFilter(vehicleReports, input)];
  });
  return reports;
}
