import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@material-ui/core";
import Select from "../select";

export default function CreateDefault({
  dialogValue,
  handleClose,
  handleSubmit,
  label,
  open,
  setDialogValue,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form>
        <DialogTitle id="form-dialog-title">{`Add a new ${label}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you need a new ${label}? Just add it...`}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.name}
            onChange={(event) =>
              setDialogValue({ ...dialogValue, name: event.target.value })
            }
            label="name"
            type="text"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
