import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://b7881b744f553c4a57537a86a434d3f4@o4506402459484160.ingest.sentry.io/4506411613814784",
    integrations: [],
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
