import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const IconLink = ({ color, dataCy, icon, iconStyle, link }) => (
  <Link to={link || "#"} className={styles.container} style={{ color: color }}>
    <i
      data-cy={dataCy}
      className={`material-icons${iconStyle ? `-${iconStyle}` : ""}`}
    >
      {icon || "face"}
    </i>
  </Link>
);

export default IconLink;
