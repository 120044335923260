import React from "react";
import styles from "./styles.module.css";
import { connect } from "react-redux";

import {
  selectRectificationsByVehicle,
  selectActionsAsArray,
} from "common/ducks/api";

import { MyTheme } from "common/modules/colors";
import SearchField from "../searchfield";
import Select from "../select";
import { searchRectificationsFilter } from "common/modules/search-filters";
import RectificationsSearchResults from "../rectificationssearchresults";
import { LinearProgress } from "@material-ui/core";
const colors = MyTheme.colors;

class RectificationsSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      searchAction: null,
      filtered: [],
      rectifications: [],
    };
  }

  componentDidMount() {
    this.SearchFilterFunction("");
  }

  componentDidUpdate(prevProps) {
    if (this.props.rectifications !== prevProps.rectifications) {
      this.SearchFilterFunction(this.state.searchInput);
    }
  }

  SearchFilterFunction = (input, action = this.state.action) => {
    let filtered = searchRectificationsFilter(
      this.props.rectifications,
      input,
      action
    );

    this.setState({
      filtered: filtered,
      searchInput: input,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.searchBar}>
          <div className={styles.textInput}>
            <SearchField
              label="Faults"
              value={this.state.searchInput}
              onChange={(e) =>
                this.SearchFilterFunction(
                  e.target.value,
                  this.state.searchAction
                )
              }
            />
          </div>

          <div className={styles.actionSearch}>
            <Select
              label="Action"
              value={this.state.searchAction}
              options={this.props.actions}
              renderOption={(option) => {
                return option.name;
              }}
              setValue={(event, value) => {
                this.setState({ searchAction: value });
                this.SearchFilterFunction(this.state.searchInput, value);
              }}
            />
          </div>
        </div>
        {this.props.isFetching && <LinearProgress />}
        {this.state.filtered.length > 0 ? (
          <RectificationsSearchResults rectifications={this.state.filtered} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    rectifications: selectRectificationsByVehicle(state, ownProps.vehicleID),
    actions: selectActionsAsArray(state),
  };
};

export default connect(mapStateToProps)(RectificationsSearch);
