import { PURGE } from "redux-persist";
import { generateNextReportIdNeg } from "../modules/utils";

// Types
const MY_REPORTS = "fleet_viper/my_reports";
const MY_REPORTS_CREATE = `${MY_REPORTS}/MY_REPORTS_CREATE`;
const MY_REPORTS_OPEN = `${MY_REPORTS}/MY_REPORTS_OPEN`;
const MY_REPORTS_DUPLICATE = `${MY_REPORTS}/MY_REPORTS_DUPLICATE`;
const MY_REPORTS_DELETE = `${MY_REPORTS}/MY_REPORTS_DELETE`;

const MY_REPORTS_SAVE = `${MY_REPORTS}/MY_REPORTS_SAVE`;
const MY_REPORTS_CANCEL = `${MY_REPORTS}/MY_REPORTS_CANCEL`;

// Action creators
export const createReport = ({ vehicle }) => ({
  type: MY_REPORTS_CREATE,
  payload: { vehicle },
});
export const openReport = ({ id, vehicle }) => ({
  type: MY_REPORTS_OPEN,
  payload: { id, vehicle },
});
export const duplicateReport = ({ id, report, vehicle }) => ({
  type: MY_REPORTS_DUPLICATE,
  payload: { id, report, vehicle },
});
export const deleteMyReport = ({ id, vehicle }) => ({
  type: MY_REPORTS_DELETE,
  payload: { id, vehicle },
});

export const saveReport = ({ report, id, vehicle }) => ({
  type: MY_REPORTS_SAVE,
  payload: { report, id, vehicle },
});

export const cancelReport = ({ id, vehicle }) => ({
  type: MY_REPORTS_CANCEL,
  payload: { id, vehicle },
});

// Reducer

const INITIAL_STATE = { my_reports: {}, current_report: null };

export default function reducer(state = INITIAL_STATE, action) {
  let my_reports;
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;

    case MY_REPORTS_CREATE:
      my_reports = { ...state.my_reports };
      if (!my_reports[action.payload.vehicle]) {
        my_reports[action.payload.vehicle] = {};
      }
      let id = generateNextReportIdNeg(my_reports[action.payload.vehicle]);
      my_reports[action.payload.vehicle][id] = action.payload.data;
      return {
        ...state,
        current_report: {
          id,
          data: null,
        },
        my_reports,
      };

    case MY_REPORTS_OPEN:
      return {
        ...state,
        current_report: {
          id: action.payload.id,
          vehicle: action.payload.vehicle,
          data: state.my_reports[action.payload.vehicle][action.payload.id],
        },
      };

    case MY_REPORTS_DUPLICATE:
      return {
        ...state,
        current_report: {
          id: action.payload.id,
          vehicle: action.payload.vehicle,
          data: action.payload.report,
        },
      };

    case MY_REPORTS_DELETE:
      my_reports = { ...state.my_reports };
      delete my_reports[action.payload.vehicle][action.payload.id];
      if (Object.keys(my_reports[action.payload.vehicle]).length == 0) {
        delete my_reports[action.payload.vehicle];
      }
      return { ...state, my_reports };

    case MY_REPORTS_SAVE:
      my_reports = { ...state.my_reports };
      if (!my_reports[action.payload.vehicle])
        my_reports[action.payload.vehicle] = {};
      my_reports[action.payload.vehicle][action.payload.id] = {
        ...action.payload.report,
        vehicle: action.payload.vehicle,
        id: action.payload.id,
      };

      return {
        ...state,
        current_report: {
          id: action.payload.id,
          vehicle: action.payload.vehicle,
          data: action.payload.report,
        },
        my_reports,
      };

    case MY_REPORTS_CANCEL:
      my_reports = { ...state.my_reports };
      if (
        state.current_report?.data == null &&
        my_reports[action.payload.vehicle]
      ) {
        delete my_reports[action.payload.vehicle][action.payload.id];
        if (Object.keys(my_reports[action.payload.vehicle]).length == 0) {
          delete my_reports[action.payload.vehicle];
        }
      }
      return { ...state, current_report: null, my_reports };

    default:
      return state;
  }
}

export const selectMyReports = (state) => state.my_reports.my_reports;

export const selectMyReportsByVehicle = (state, vehicle) =>
  state.my_reports.my_reports[vehicle] || {};

export const selectMyCompletedReportsByVehicle = (state, vehicle) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports[vehicle]).map((reportID) => {
      let report = state.my_reports.my_reports[vehicle][reportID];
      report["id"] = reportID;
      if (report.completed) {
        reports.push(report);
      }
    });
    return reports;
  } catch {
    return [];
  }
};

export const selectMyCompletedReports = (state) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports).map((vehicleID) =>
      Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
        let report = state.my_reports.my_reports[vehicleID][reportID];
        report["id"] = reportID;
        if (report.completed) {
          reports.push(report);
        }
      })
    );
    return reports;
  } catch {
    return [];
  }
};

export const selectUncheckedReports = (state) => {
  try {
    const vehicleIDs = Object.keys(state.my_reports.my_reports);
    let reports = {};
    vehicleIDs.map((vehicleID) => {
      const reportsByVehicle = selectUncheckedReportsByVehicle(
        state,
        vehicleID
      );

      if (Object.keys(reportsByVehicle).length > 0) {
        reports[vehicleID] = reportsByVehicle;
      }
    });
    return reports;
  } catch {
    return {};
  }
};

export const selectMyUncheckedReportsCount = (state) => {
  return selectUncheckedReportsAsArray(state).length;
};

export const selectUncheckedReportsByVehicle = (state, vehicleID) => {
  try {
    let reports = {};

    Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
      let report = state.my_reports.my_reports[vehicleID][reportID];
      report["id"] = reportID;
      if (!report.checked_date) {
        reports[reportID] = report;
      }
    });

    return reports;
  } catch {
    return {};
  }
};

export const selectMyUncheckedReportsAsArray = (state) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports).map((vehicleID) =>
      Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
        let report = state.my_reports.my_reports[vehicleID][reportID];
        report["id"] = reportID;
        if (!report.checked_by) {
          reports.push(report);
        }
      })
    );
    return reports;
  } catch {
    return [];
  }
};

export const selectMyUncheckedReportsAsArrayWithoutDuplicates = (state) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports).map((vehicleID) =>
      Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
        let report = state.my_reports.my_reports[vehicleID][reportID];
        report["id"] = reportID;
        if (report.id > -1 && !report.checked_by) {
          reports.push(report);
        }
      })
    );
    return reports;
  } catch {
    return [];
  }
};

export const selectMyUncheckedReportsWithoutDuplicatesCount = (state) => {
  return selectMyUncheckedReportsAsArrayWithoutDuplicates(state).length;
};

export const selectMyCompletedReportsCount = (state) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports).map((vehicleID) =>
      Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
        let report = state.my_reports.my_reports[vehicleID][reportID];
        report["id"] = reportID;
        if (report.completed) {
          reports.push(report);
        }
      })
    );
    return reports.length.toString();
  } catch {
    return "0";
  }
};

export const selectMyIncompletedReportsCount = (state) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports).map((vehicleID) =>
      Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
        let report = state.my_reports.my_reports[vehicleID][reportID];
        report["id"] = reportID;
        if (!report.completed) {
          reports.push(report);
        }
      })
    );
    return reports.length.toString();
  } catch {
    return "0";
  }
};

export const selectMyIncompletedReports = (state) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports).map((vehicleID) =>
      Object.keys(state.my_reports.my_reports[vehicleID]).map((reportID) => {
        let report = state.my_reports.my_reports[vehicleID][reportID];
        report["id"] = reportID;
        if (!report.completed) {
          reports.push(report);
        }
      })
    );
    return reports;
  } catch {
    return [];
  }
};

export const selectMyIncompletedReportsByVehicle = (state, vehicle) => {
  try {
    let reports = [];
    Object.keys(state.my_reports.my_reports[vehicle]).map((reportID) => {
      let report = state.my_reports.my_reports[vehicle][reportID];
      report["id"] = reportID;
      if (!report.completed) {
        reports.push(report);
      }
    });
    return reports;
  } catch {
    return [];
  }
};
export const selectCurrentReport = (state) => state.my_reports.current_report;

export const selectInspecting = (state) =>
  state.my_reports.current_report !== null;
