import React from "react";
import { connect } from "react-redux";

import styles from "./styles.module.css";

import { reportSearchFilter } from "common/modules/search-filters";

import { MyTheme } from "common/modules/colors";

import {
  selectReportsDownloaded,
  selectPendingReports,
  selectToken,
  fetchPendingReports,
  deleteReport,
  selectLastUpdated,
  fetchReports,
} from "common/ducks/api";
import ReportsSearchResults from "../reportssearchresults";
import { deleteMyReport, selectUncheckedReports } from "common/ducks/report";
import { allReportsToArray, mergeAllReports } from "common/modules/utils";

import SearchField from "../searchfield";

const colors = MyTheme.colors;

class ReportSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      filtered: [],
      reports: [],
      isReady: false,
    };
  }

  componentDidMount() {
    this.initialiseDefaults();
  }

  initialiseDefaults = async () => {
    // Get actions from storage
    // Save to state
    // Get actions from server after last_updated
    // Merge into state
    this.SearchFilterFunction("");

    await this.props.getReports({
      token: this.props.token,
      last_updated: this.props.last_updated,
    });

    this.SearchFilterFunction("");
  };

  // @TODO Only need to pass id and reg.  No need for full reports here

  filteredToReports = () => {
    return this.state.filtered;
  };

  SearchFilterFunction = (searchInput, reports = this.props.reports) => {
    const filtered = reportSearchFilter(reports, searchInput);
    this.setState({
      filtered: filtered,
      searchInput: searchInput,
    });
  };

  handleDelete = async ({ id, vehicle }) => {
    if (id < 0) {
      await this.props.deleteMyReport({ token: this.props.token, id, vehicle });
    } else {
      await this.props.deleteReport({ token: this.props.token, id, vehicle });
    }
    this.props.getReports({
      token: this.props.token,
      last_updated: this.props.last_updated,
    });
    this.SearchFilterFunction("");
  };

  handleInput = (input) => {
    this.setState({ searchInput: input });
  };

  render() {
    return (
      <div className={styles.container}>
        <SearchField
          label="Reports"
          value={this.state.searchInput}
          onChange={(e) => this.SearchFilterFunction(e.target.value)}
        />

        {this.state.filtered.length > 0 ? (
          <ReportsSearchResults
            vehicle={true}
            reports={this.state.filtered}
            handleDelete={this.handleDelete}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: selectToken(state),
    last_updated: selectReportsDownloaded(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReports: async ({ token, last_updated }) => {
      await dispatch(fetchPendingReports({ token, last_updated }));
      await dispatch(fetchReports({ token, last_updated }));
    },
    deleteMyReport: async ({ id, vehicle }) => {
      await dispatch(deleteMyReport({ id, vehicle }));
    },
    deleteReport: async ({ token, id, vehicle }) => {
      await dispatch(deleteReport({ token, id, vehicle }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSearch);
