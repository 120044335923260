import React from "react";
import { ListItemText, Typography } from "@material-ui/core";

import styles from "./styles.module.css";

const RectificationSearchResult = ({ color, rectification }) => {
  return (
    <div className={styles.container} style={{ color: color }}>
      <ListItemText
        primary={
          <React.Fragment>
            {`${rectification.action.name}: `}
            <Typography
              component="span"
              variant="subtitle2"
              className={styles.inline}
              color="textPrimary"
            >
              {`IM: ${rectification.fault.im_no} - ${rectification.fault.name}`}
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            {rectification.description}
            <Typography
              component="span"
              variant="subtitle2"
              className={styles.inline}
              color="textPrimary"
            >
              {` (${new Date(rectification.date).toDateString()})`}
            </Typography>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default RectificationSearchResult;
