import React from "react";
import styles from "./styles.module.css";

import { TextField } from "@material-ui/core";

export default function TextInput({
  dataCy,
  label,
  defaultValue,
  onBlur,
  onChange,
  size,
  type,
}) {
  return (
    <div className={styles.container} data-cy={dataCy}>
      <TextField
        className={styles.textfield}
        label={label}
        variant="outlined"
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        type={type || "text"}
        size={size}
      />
    </div>
  );
}
