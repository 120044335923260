import React from "react";
import styles from "./styles.module.css";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import defaultStates, {
  makeReportTyreChecksDefault,
} from "common/modules/default-states";
import { apiReportToAppReport } from "common/services/api";
import TextInput from "../textinput";

import Select from "../select";
import InputDate from "../inputdate";
import Rectifications from "../rectifications";
import { deletePart, deleteRectification } from "common/modules/utils";
import Checks, { getMarkingCodeIcon } from "../checks";
import TyreCheck from "../tyrecheck";
import { MyTheme } from "common/modules/colors";
import { selectTachographNextDate } from "common/ducks/api";
import Parts from "../parts";
import AlertDialog from "../dialog";

// import { cleanReportInfo } from "common/modules/utils";

const Inspect = ({
  actions,
  createDefault,
  faults,
  onChange,
  report,
  reportTypes,
  tachographNextDate,
  user,
  users,
  vehicle,
}) => {
  // id of less than zero means it's my_report.  Else from API.
  report = report && report.id >= 0 ? apiReportToAppReport(report) : report;

  const createChecksWithMC = ({ faults, mc }) =>
    faults.map((fault) => ({
      fault: fault.id,
      marking_code: mc,
    }));

  const emptyChecks = createChecksWithMC({ faults, mc: "NC" });

  const indexOfCheck = (faultID) => {
    for (let i = 0; i < emptyChecks.length; i++) {
      if (emptyChecks[i].fault == faultID) {
        return i;
      }
    }
    return -1;
  };

  const createChecks = ({ report }) => {
    if (report.checks?.length > 0) return report.checks;

    const checks = [...emptyChecks];

    if (report?.rectifications) {
      Object.values(report.rectifications).forEach(
        (rectification) =>
          (checks[indexOfCheck(rectification.fault.id)] = {
            fault: rectification.fault.id,
            marking_code: "D",
          })
      );
    }

    return checks;
  };

  const markRemainingChecksWithMC = ({ report, mc }) => {
    const checks = createChecksWithMC({ faults, mc: mc });

    Object.values(report.rectifications).forEach(
      (rectification) =>
        (checks[indexOfCheck(rectification.fault.id)] = {
          fault: rectification.fault.id,
          marking_code: "D",
        })
    );
    setChecks(checks);
  };
  if (!report.checks || report.checks.length === 0) {
    report = { ...report, checks: createChecks({ report }) };
  }

  const [editing, setEditing] = React.useState({});

  const [rectifications, setRectifications] = React.useState(
    (report && report.rectifications) || []
  );

  const [parts, setParts] = React.useState((report && report.parts) || []);
  const [nextPartID, setNextPartID] = React.useState(-1);

  const [tyres, setTyres] = React.useState(
    makeReportTyreChecksDefault(report.tyres)
  );

  const [checks, setChecks] = React.useState(report.checks);

  const defaultCheckedInfo = report.checkedInfo?.checker?.id
    ? report.checkedInfo
    : { ...defaultStates.CHECKED_INFO };

  const [checkedInfo, setCheckedInfo] = React.useState(defaultCheckedInfo);

  const [reportInfo, setReportInfo] = React.useState(
    (report && report.reportInfo) || {
      ...defaultStates.REPORT_INFO,
      inspector: user,
    }
  );

  const [vehicleInfo, setVehicleInfo] = React.useState(
    (report && report.vehicleInfo) || {
      ...defaultStates.VEHICLE_INFO,
      tachograph: {
        date: tachographNextDate || new Date(),
        expanded: false,
      },
      vehicle: vehicle,
    }
  );

  const changeCheck = (fault, marking_code, prev = undefined) => {
    const newChecks = [...checks];
    if (prev !== undefined) {
      newChecks[indexOfCheck(prev)] = { fault: prev, marking_code: "NC" };
    }
    newChecks[indexOfCheck(fault)] = { fault, marking_code };
    setChecks(newChecks);
  };

  // If editing state changes, execute onChange to pass up to report
  React.useEffect(() => {
    onChange({
      report: {
        checkedInfo: { ...checkedInfo },
        reportInfo: { ...reportInfo },
        vehicleInfo: { ...vehicleInfo },
        rectifications: { ...rectifications },
        checks: [...checks],
        tyres: [...tyres],
        parts: [...parts],
      },
      isEditing: isEditing(),
    });
  }, [editing]);

  const handleChange = (key, change, isEditing) => {
    change(); // execute setState on report section
    if (typeof key === "string") {
      setEditing({ ...editing, [key]: isEditing });
    } else {
      setEditing({ ...editing, ...key });
    }
  };

  const isEditing = () => {
    return Object.keys(editing).some((key) => editing[key]);
  };

  const [importingInvoice, setImportingInvoice] = React.useState(false);
  const [convertingInvoice, setConvertingInvoice] = React.useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <ReportSection title="Checker Info">
          <CheckedInfo
            current={report && report.checkedInfo}
            checkedInfo={checkedInfo}
            setFunction={setCheckedInfo}
            handleChange={handleChange}
            users={users}
          />
        </ReportSection>
        <ReportSection title="Report Info">
          <ReportInfo
            createDefault={createDefault}
            current={report && report.reportInfo}
            reportInfo={reportInfo}
            setFunction={setReportInfo}
            handleChange={handleChange}
            reportTypes={reportTypes}
          />
        </ReportSection>

        <ReportSection title="Vehicle Info">
          <VehicleInfo
            current={report && report.vehicleInfo}
            vehicleInfo={vehicleInfo}
            setFunction={setVehicleInfo}
            handleChange={handleChange}
            tachographNextDate={tachographNextDate}
          />
        </ReportSection>
      </div>

      <div className={styles.rectifications}>
        <ReportSection title="Rectifications">
          <Rectifications
            changeCheck={changeCheck}
            createDefault={createDefault}
            current={report && report.rectifications}
            handleChange={handleChange}
            rectifications={rectifications}
            setFunction={setRectifications}
            actions={actions}
            faults={faults}
            users={users}
            deleteRectification={(id) =>
              setRectifications(deleteRectification(id, rectifications))
            }
          />
          <Button
            data-cy="add-rectification"
            color="primary"
            onClick={() =>
              setRectifications({
                ...rectifications,
                [Object.keys(rectifications).length]: {
                  ...defaultStates.RECTIFICATION,
                },
              })
            }
          >
            Add rectification
          </Button>
        </ReportSection>
      </div>
      <div className={styles.rectifications}>
        <ReportSection
          title="Parts"
          action={
            <Button
              data-cy="import-invoice"
              color="primary"
              onClick={() => setImportingInvoice(true)}
            >
              Import invoice
            </Button>
          }
        >
          <AlertDialog
            message="Please select an invoice to import"
            handleClose={() => setImportingInvoice(false)}
            handleOk={() => {
              setImportingInvoice(false);
              setConvertingInvoice(true);
            }}
            open={importingInvoice}
            okTitle="Import"
            title="Import from invoice"
          >
            <Button
              color="primary"
              variant="contained"
              component="label"
              data-cy="browse"
            >
              <input
                type="file"
                onChange={(e) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onload = () => {
                    console.log(JSON.stringify({ dataURL: reader.result }));
                  };
                }}
              />
            </Button>
          </AlertDialog>

          <AlertDialog
            message="Please email this invoice to info@fleetviper.com to get support"
            handleClose={() => setConvertingInvoice(false)}
            handleOk={() => setConvertingInvoice(false)}
            open={convertingInvoice}
            okTitle="OK"
            title="Error: Unsupported invoice"
            noCancel={true}
          ></AlertDialog>

          <Parts
            changeCheck={changeCheck}
            current={report && report.parts}
            handleChange={handleChange}
            parts={parts}
            setFunction={setParts}
            deletePart={(id) => setParts(deletePart(id, parts))}
          />
          <Button
            data-cy="add-part"
            color="primary"
            onClick={() => {
              setParts([...parts, { ...defaultStates.PART, id: nextPartID }]);
              setNextPartID(nextPartID - 1);
            }}
          >
            Add part
          </Button>
        </ReportSection>
      </div>
      <div className={styles.tyres}>
        <ReportSection title="Tyres">
          <TyreCheck
            current={report && report.tyres}
            tyreChecks={tyres}
            setFunction={setTyres}
            handleChange={handleChange}
          />
        </ReportSection>
      </div>
      <div className={styles.checks}>
        <ReportSection
          title={
            <div>
              Checks
              <Button
                onClick={() => {
                  const editingObject = {};

                  report.checks.forEach((check, index) => {
                    if (check.marking_code === "NC") {
                      editingObject[`checks-${index}`] = true;
                    }
                  });

                  handleChange(editingObject, () =>
                    markRemainingChecksWithMC({ report, mc: "S" })
                  );
                }}
              >
                {getMarkingCodeIcon("S")}remaining
              </Button>
            </div>
          }
        >
          <Checks
            checks={checks}
            current={report && report.checks}
            setFunction={setChecks}
            handleChange={handleChange}
          />
        </ReportSection>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    tachographNextDate: selectTachographNextDate(state, ownProps.vehicle),
  };
};

export default connect(mapStateToProps)(Inspect);

const ReportInfo = ({
  current,
  createDefault,
  handleChange,
  reportInfo,
  reportTypes,
  setFunction,
}) => {
  return (
    <div className={styles.infoFields}>
      <Select
        dataCy="report-type"
        label="Report Type"
        value={reportInfo.reportType}
        options={reportTypes}
        createNewItem={({ data }) =>
          createDefault({ my_default: "my_reporttypes", data })
        }
        renderOption={(option) => {
          return option.name;
        }}
        setValue={(event, value) => {
          let isEditing =
            (value && value.name) !==
            (current && current.reportType && current.reportType.name);
          handleChange(
            "reportInfo.reportType",
            () => setFunction({ ...reportInfo, reportType: value }),
            isEditing
          );
        }}
      />
      <InputDate
        dataCy="inspection-date"
        label="Inspection Date"
        value={reportInfo.inspectionDate.date}
        onChange={(value) => {
          let isEditing =
            Date.parse(value) !==
            (current && Date.parse(current.inspectionDate.date));

          handleChange(
            "reportInfo.inspectionDate",
            () =>
              setFunction({
                ...reportInfo,
                inspectionDate: { date: value },
              }),
            isEditing
          );
        }}
      />
    </div>
  );
};

const VehicleInfo = ({
  current,
  handleChange,
  setFunction,
  tachographNextDate,
  vehicleInfo,
}) => {
  const [typeTimeout, setTypeTimeout] = React.useState(null);

  const handleTypeOdometer = (event) => {
    let value = event.target.value && parseInt(event.target.value);
    let isEditing =
      value !== (current && current.odometer && current.odometer.reading);

    handleChange(
      "vehicleInfo.odometer",
      () =>
        setFunction({
          ...vehicleInfo,
          odometer: { reading: value },
        }),
      isEditing
    );
  };

  return (
    <div className={styles.infoFields}>
      <TextInput
        dataCy="odometer"
        defaultValue={vehicleInfo.odometer.reading}
        onChange={(event) => {
          if (typeTimeout) clearTimeout(typeTimeout);
          setTypeTimeout(() => {
            setTimeout(() => handleTypeOdometer(event), 1000);
          });
        }}
        onBlur={handleTypeOdometer}
        label="Odometer (km)"
        type="number"
      />
      <InputDate
        dataCy="next-tachograph-date"
        label="Next Tachograph Date"
        value={vehicleInfo.tachograph.date}
        onChange={(value) => {
          let isEditing =
            Date.parse(value) !==
            (current && Date.parse(current.tachograph.date));

          handleChange(
            "vehicleInfo.tachograph",
            () =>
              setFunction({
                ...vehicleInfo,
                tachograph: { date: value },
              }),
            isEditing
          );
        }}
      />
    </div>
  );
};

const CheckedInfo = ({
  current,
  handleChange,
  checkedInfo,
  users,
  setFunction,
}) => {
  return (
    <div className={styles.infoFields}>
      {/* <Select
        label="Report Type"
        value={checkedInfo.reportType}
        options={users}
        renderOption={(option) => {
          return option.name;
        }}
        setValue={(event, value) => {
          let isEditing =
            (value && value.name) !==
            (current && current.reportType && current.reportType.name);
          handleChange(
            "checkedInfo.reportType",
            () => setFunction({ ...checkedInfo, reportType: value }),
            isEditing
          );
        }}
      /> */}

      <Select
        dataCy="checker"
        label="Checked by"
        value={checkedInfo.checker}
        options={users}
        renderOption={(option) => {
          return `${option.first_name} ${option.last_name}`;
        }}
        setValue={(event, value) => {
          let isEditing = (value && value.id) !== current?.checker?.id;

          handleChange(
            `checkedInfo.checker`,
            () => setFunction({ ...checkedInfo, checker: value }),
            isEditing
          );
        }}
      />

      <InputDate
        label="Checked Date"
        value={checkedInfo.checkedDate.date || new Date()}
        onChange={(value) => {
          let isEditing =
            Date.parse(value) !==
            (current && Date.parse(current.checkedDate.date));

          handleChange(
            "checkedInfo.checkedDate",
            () =>
              setFunction({
                ...checkedInfo,
                checkedDate: { date: value },
              }),
            isEditing
          );
        }}
      />
    </div>
  );
};

const ReportSection = ({ children, title, action }) => (
  <Card
    raised={true}
    style={{
      backgroundColor: MyTheme.colors.secondaryCardLightest,
    }}
    className={styles.card}
  >
    <CardHeader title={title} action={action} />
    <CardContent>{children}</CardContent>
  </Card>
);
