import React from "react";
import styles from "./styles.module.css";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function InputDate({
  clearable,
  dataCy,
  value,
  label,
  onChange,
}) {
  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable={clearable}
        className={styles.input}
        // className not enough, need to use style for margins
        style={{ margin: 0, marginBottom: "0.5rem" }}
        inputVariant="outlined"
        data-cy={dataCy}
        margin="normal"
        id="date-picker-dialog"
        label={label}
        format="dd/MM/yyyy"
        value={value}
        onChange={handleChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
