import React from "react";
import styles from "./styles.module.css";
import { connect } from "react-redux";

import Card from "@material-ui/core/Card";

import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import { MyTheme } from "common/modules/colors";
import { CardHeader, CircularProgress, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  changePassword,
  selectChangePasswordStatus,
  selectToken,
  setPasswordChangeStatus,
} from "common/ducks/api";
const colors = MyTheme.colors;

const Settings = (props) => {
  const [oldpassword, setOldpassword] = React.useState("");
  const [newpassword, setNewpassword] = React.useState("");
  const [newpassword2, setNewpassword2] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setOldpassword("");
    setNewpassword("");
    setNewpassword2("");
    setErrors([]);
    props.clearPasswordChangeStatus();

    if (oldpassword == "" || newpassword == "" || newpassword2 == "") {
      setErrors(["Fields can't be blank"]);
      return;
    }

    if (newpassword !== newpassword2) {
      setErrors(["New passwords don't match"]);
      return;
    }

    await props.changePassword({
      token: props.token,
      oldpassword,
      newpassword,
    });
  };
  return (
    <div className={styles.container}>
      <Card className={styles.formcontainer}>
        <CardHeader title="Change password" />
        {props.changePasswordStatus === "SUCCESS" && (
          <Alert severity="success">Successfully changed password</Alert>
        )}
        {errors.map((error, index) => (
          <Alert key={index} severity="error">
            {error}
          </Alert>
        ))}
        {props.changePasswordStatus === "ERROR" && (
          <Alert severity="error">Cannot set password</Alert>
        )}
        <form
          className={styles.form}
          onSubmit={handleChangePassword}
          data-cy="change password"
        >
          <TextField
            label="Old password"
            variant="outlined"
            type="password"
            value={oldpassword}
            onChange={(e) => setOldpassword(e.target.value)}
            data-cy="Old password"
          />
          <TextField
            label="New password"
            variant="outlined"
            type="password"
            value={newpassword}
            onChange={(e) => setNewpassword(e.target.value)}
            data-cy="New password"
          />
          <TextField
            label="Confirm new password"
            variant="outlined"
            type="password"
            value={newpassword2}
            onChange={(e) => setNewpassword2(e.target.value)}
            data-cy="Confirm new password"
          />
          <CardActions className={styles.actions}>
            <Button
              className={styles.submit}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              data-cy="Change password"
            >
              {props.changePasswordStatus === "START" ? (
                <CircularProgress color="inherit" size="1.5em" />
              ) : (
                "Change password"
              )}
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: selectToken(state),
    changePasswordStatus: selectChangePasswordStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: async ({ token, oldpassword, newpassword }) => {
      await dispatch(changePassword({ token, oldpassword, newpassword }));
    },
    clearPasswordChangeStatus: () => dispatch(setPasswordChangeStatus(null)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
