import * as React from "react";
import styles from "./styles.module.css";
import { Card, CardHeader, CardContent, Button } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import WarningIcon from "@material-ui/icons/Warning";

import { connect } from "react-redux";

import { selectFaults } from "common/ducks/api";
import { cleanFaultName, getStyleName } from "common/modules/utils";

const S = "S";
const D = "D";
const NA = "NA";
const NC = "NC";
const RN = "RN";
const M = "M";

export const MARKING_CODES = [NC, S, D, NA];

const incrementMarkingCode = (_c) => {
  const newCodeIndex = (MARKING_CODES.indexOf(_c) + 1) % MARKING_CODES.length;
  return MARKING_CODES[newCodeIndex];
};

function getCategorySpans(categoryLengths) {
  const minSpan = Math.min(...categoryLengths);
  return categoryLengths.map((length) => Math.round(length / minSpan));
}

function getMarkingCodeColour(_c, lighten = 1) {
  const colours = {
    [S]: `rgba(105, 173, 80, ${1 * lighten})`,
    [D]: `rgba(173,105,80, ${1 * lighten})`,
    [NA]: `rgba(0,0,0, ${0.3 * lighten})`,
    [NC]: "white",
    [RN]: "white",
    [M]: "white",
  };
  return colours[_c];
}

export function getMarkingCodeIcon(_c) {
  // ✓
  const icons = {
    // [S]: (
    //   <CheckCircleIcon
    //     fontSize="inherit"
    //     style={{
    //       color: getMarkingCodeColour(S),
    //     }}
    //   />
    // ),
    [S]: (
      <Typography style={{ fontWeight: "bold", fontSize: "0.8em" }}>
        ✓
      </Typography>
    ),

    [D]: (
      <HighlightOffIcon
        fontSize="inherit"
        style={{
          color: getMarkingCodeColour(D),
        }}
      />
    ),
    [NA]: <Typography style={{ fontSize: "0.8em" }}>N/A</Typography>,
    [NC]: <Typography></Typography>,
    [RN]: <Typography>R</Typography>,
    [M]: <Typography>M</Typography>,
  };
  return icons[_c];
}

function Checks({ checks, current, faults, handleChange, setFunction }) {
  const categories = [];
  const categoryNames = [];
  const categoryLengths = [];
  const categoryIndices = [];
  let categorySpans;
  let currentCategory = null;

  // Iterate through all checks
  for (let i = 0; i < checks.length; i++) {
    if (faults[checks[i].fault].category.id !== currentCategory) {
      currentCategory = faults[checks[i].fault].category.id;
      categories.push([]);
      categoryNames.push(faults[checks[i].fault].category.name);
      if (i !== 0) {
        categoryLengths.push(i - categoryLengths.reduce((a, b) => a + b, 0));

        if (categoryIndices.length > 0) {
          categoryIndices.push([
            categoryIndices[categoryIndices.length - 1][1],
            i,
          ]);
        } else {
          categoryIndices.push([0, i]);
        }
      }
    }

    categories[categories.length - 1].push(
      <ListItem
        style={{
          backgroundColor: getMarkingCodeColour(checks[i].marking_code, 0.5),
        }}
        key={i}
        button
        onClick={() => {
          let isEditing =
            incrementMarkingCode(checks[i].marking_code) !==
            (current && current[i].marking_code);

          handleChange(
            `checks-${i}`,
            () =>
              setFunction(
                checks.map((check) =>
                  check.fault === checks[i].fault
                    ? {
                        ...check,
                        marking_code: incrementMarkingCode(
                          checks[i].marking_code
                        ),
                      }
                    : check
                )
              ),
            isEditing
          );
        }}
      >
        {faults[checks[i].fault].name.includes("BRAKES - ") ? (
          <ListItemText
            style={{ paddingLeft: "1rem" }}
            primary={`${
              faults[checks[i].fault].im_no &&
              faults[checks[i].fault].im_no + " - "
            }${cleanFaultName(faults[checks[i].fault].name)}`}
          />
        ) : (
          <ListItemText
            primary={`${
              faults[checks[i].fault].im_no &&
              faults[checks[i].fault].im_no + " - "
            }${faults[checks[i].fault].name}`}
          />
        )}

        <ListItemIcon>
          {getMarkingCodeIcon(checks[i].marking_code)}
        </ListItemIcon>
      </ListItem>
    );
  }
  categoryLengths.push(
    checks.length - categoryLengths.reduce((a, b) => a + b, 0)
  );
  categorySpans = getCategorySpans(categoryLengths);
  categoryIndices.push([
    categoryIndices[categoryIndices.length - 1][1],
    checks.length,
  ]);

  return (
    <div className={styles.checksContainer}>
      {categories.map((category, index) => (
        <Card
          key={index}
          className={styles.checkContainer}
          style={{ gridArea: getStyleName(categoryNames[index]) }}
        >
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {categoryNames[index]}
              <Button
                onClick={() => {
                  let isEditing =
                    current &&
                    current
                      .slice(...categoryIndices[index])
                      .some((check) => check.marking_code !== NC);

                  const editingObject = {};
                  current &&
                    current
                      .slice(...categoryIndices[index])
                      .forEach(
                        (check, sliceIndex) =>
                          (editingObject[
                            `checks-${categoryIndices[index][0] + sliceIndex}`
                          ] = isEditing)
                      );
                  handleChange(
                    editingObject,
                    () =>
                      setFunction(
                        checks.map((check, checkIndex) =>
                          checkIndex >= categoryIndices[index][0] &&
                          checkIndex < categoryIndices[index][1]
                            ? {
                                ...check,
                                marking_code: NC,
                              }
                            : check
                        )
                      ),
                    isEditing
                  );
                }}
                style={{ float: "right" }}
              >
                Clear all
              </Button>

              <Button
                onClick={() => {
                  let isEditing =
                    current &&
                    current
                      .slice(...categoryIndices[index])
                      .some((check) => check.marking_code !== S);

                  const editingObject = {};
                  current &&
                    current
                      .slice(...categoryIndices[index])
                      .forEach(
                        (check, sliceIndex) =>
                          (editingObject[
                            `checks-${categoryIndices[index][0] + sliceIndex}`
                          ] = isEditing)
                      );
                  handleChange(
                    editingObject,
                    () =>
                      setFunction(
                        checks.map((check, checkIndex) =>
                          checkIndex >= categoryIndices[index][0] &&
                          checkIndex < categoryIndices[index][1] &&
                          checks[checkIndex].marking_code == NC
                            ? {
                                ...check,
                                marking_code: S,
                              }
                            : check
                        )
                      ),
                    isEditing
                  );
                }}
                style={{ float: "right" }}
              >
                {getMarkingCodeIcon("S")}rest
              </Button>
            </Typography>
            <List dense> {category}</List>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    faults: selectFaults(state),
  };
};

export default connect(mapStateToProps)(Checks);
