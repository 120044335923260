import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const newLineToBreak = (text) =>
  text.split("\\n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

export default function AlertDialog({
  message,
  handleClose,
  handleOk,
  open,
  okTitle,
  title,
  children,
  noCancel,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {newLineToBreak(message)}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {!noCancel && (
          <Button data-cy="cancel" onClick={handleClose} color="primary">
            Cancel
          </Button>
        )}
        <Button data-cy="ok" onClick={handleOk} color="primary" autoFocus>
          {okTitle || "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
