import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { connect, Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import localforage from "localforage";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import "./App.css";
import SideMenu from "./components/sidemenu";

import api, {
  fetchActions,
  fetchToken,
  selectToken,
  selectUser,
  setToken,
  fetchFaults,
  fetchReportTypes,
  setUser,
  fetchUser,
  fetchCategories,
  selectActionsAsArray,
  selectCategoriesAsArray,
  selectFaultsAsArray,
  selectReportTypesAsArray,
  selectPendingReportsCount,
  selectPendingReports,
  fetchUsers,
  selectUsersAsArray,
} from "common/ducks/api";
import app, { selectOnline, appSetOnline } from "common/ducks/app";
import my_reports, {
  selectMyCompletedReportsCount,
  selectMyIncompletedReportsCount,
  selectMyReports,
  selectMyUncheckedReportsWithoutDuplicatesCount,
  selectUncheckedReports,
} from "common/ducks/report";
import my_vehicles, { selectMyVehiclesCount } from "common/ducks/vehicle";
import my_defaults from "common/ducks/defaults";
import LogIn from "./components/login";
import VehicleSearch from "./components/vehiclessearch";
import Vehicle from "./components/vehicle";
import ReportSearch from "./components/reportssearch";
import Report from "./components/report";
import { allReportsToArray, mergeAllReports } from "common/modules/utils";
import ResetPassword from "./components/resetpassword";
import Settings from "./components/settings";
import Schedule from "./components/schedule";
import LogOutConfirm from "./components/logoutconfirm";

class Start extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      online: false,
      token: null,
      errors: [],
      showLogOutWarning: false,
      logOutConfirmed: false,
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  componentWillUnmount() {
    // this.logOut(); // FOR TESTING PURPOSES ONLY
  }

  checkToken = async () => {
    let token = localStorage.getItem("token");
    if (token) {
      this.props.setToken(token);
      await this.props.getUser({ token });
      if (this.props.user) {
        this.loadDefaults();
      }
    }
  };

  logIn = async ({ username, password }) => {
    this.setState({ errors: [] });

    await this.props.getToken({ username, password });
    if (username == "" || password == "") {
      if (username == "") {
        this.setState({ errors: ["Username can't be blank"] });
      }
      if (password == "") {
        this.setState((prev) => ({
          errors: [...prev.errors, "Password can't be blank"],
        }));
      }
    } else if (this.props.token) {
      localStorage.setItem("token", this.props.token);
      await this.props.getUser({ token: this.props.token });
      if (this.props.user) {
        this.resetLogout();
        this.loadDefaults();
      }
    } else {
      this.setState({ errors: ["Invalid username or password"] });
    }
  };

  loadDefaults = async () => {
    this.props.getUsers({
      token: this.props.token,
      last_updated: this.props.users.downloaded,
    });
    this.props.getActions({
      token: this.props.token,
      last_updated: this.props.actions.downloaded,
    });
    this.props.getCategories({
      token: this.props.token,
      last_updated: this.props.categories.downloaded,
    });
    this.props.getFaults({
      token: this.props.token,
      last_updated: this.props.faults.downloaded,
    });
    this.props.getReportTypes({
      token: this.props.token,
      last_updated: this.props.reportTypes.downloaded,
    });
  };

  logOut = () => {
    localStorage.removeItem("token");
    persistor.purge();
  };

  confirmLogout = () => {
    this.setState({
      logOutConfirmed: true,
    });
  };

  resetLogout = () => {
    this.setState({
      logOutConfirmed: false,
    });
  };

  showLogoutWarning = () => {
    this.setState({
      showLogOutWarning: true,
    });
  };

  closeLogoutWarning = () => {
    this.setState({ showLogOutWarning: false });
  };

  showLogoutWarningOrLogout = () => {
    if (this.props.offlineReports.length > 0) {
      this.showLogoutWarning();
    } else {
      this.confirmLogout();
    }
  };

  render() {
    if (!this.props.token || !this.props.user) {
      return (
        <Router>
          <Switch>
            {process.env.REACT_APP_FEATURE_CHANGE_PASSWORD && (
              <Route path="/passwordreset">
                <ResetPassword reset={this.logIn} errors={this.state.errors} />
              </Route>
            )}
            <Route path="/">
              <LogIn logIn={this.logIn} errors={this.state.errors} />
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router>
          <div id="app" className="App">
            <SideMenu
              reportCount={this.props.reports.length}
              showLogoutWarning={this.showLogoutWarningOrLogout}
            />
            <Switch>
              <Route path="/reports">
                <Reports reports={this.props.reports} />
              </Route>
              <Route path="/vehicles">
                <Vehicles />
              </Route>
              <Route path="/schedule">
                <Schedule />
              </Route>
              <Route path="/logout">
                {!this.state.logOutConfirmed && (
                  <Fragment>
                    <LogOutConfirm
                      isOpen={this.state.showLogOutWarning}
                      close={this.closeLogoutWarning}
                      confirm={this.confirmLogout}
                      numOfUnsavedReports={this.props.offlineReports.length}
                    />
                    <Reports reports={this.props.offlineReports} />
                  </Fragment>
                )}

                {this.state.logOutConfirmed && <LogOut logOut={this.logOut} />}
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      );
    }
  }
}
function Reports({ reports }) {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/:vehicleID/:reportID`}>
        <GetReport />
      </Route>
      <Route path={match.url}>
        <ReportSearch reports={reports} />
      </Route>
    </Switch>
  );
}

function Home() {
  return <Redirect to="/vehicles" />;
}

function LogOut({ logOut }) {
  logOut();
  return <Redirect to="/" />;
}

function Vehicles() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/:vehicleID`}>
        <GetVehicle />
      </Route>
      <Route path={match.url}>
        <VehicleSearch />
      </Route>
    </Switch>
  );
}

function GetVehicle() {
  let { vehicleID } = useParams();
  return <Vehicle vehicleID={vehicleID} />;
}

function GetReport() {
  let { reportID, vehicleID } = useParams();
  return <Report vehicleID={vehicleID} reportID={reportID} />;
}

const loggerMiddleware = createLogger();

// ############## WITH REDUX PERSIST ##############
const rootPersistConfig = {
  key: "root",
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["api", "app"],
};

const apiPersistConfig = {
  key: "api",
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["fetching"],
};

const rootReducer = combineReducers({
  api: persistReducer(apiPersistConfig, api),
  app,
  my_reports,
  my_vehicles,
  my_defaults,
});

const pReducer = persistReducer(rootPersistConfig, rootReducer);

const middlewares = [];

middlewares.push(thunkMiddleware);
if (process.env.NODE_ENV === "development") {
  middlewares.push(loggerMiddleware);
}

export const store = createStore(pReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);
// persistor.purge(); // USED FOR DEBUGGING

// // ############## WITHOUT REDUX PERSIST ##############
// const rootReducer = combineReducers({
//   api,
//   app,
//   my_reports,
// });

// export const store = createStore(
//   rootReducer,
//   applyMiddleware(thunkMiddleware, loggerMiddleware)
// );
// // ############## WITHOUT REDUX PERSIST ##############

const mapDispatchToProps = (dispatch) => {
  return {
    getToken: async ({ username, password }) => {
      await dispatch(fetchToken({ username, password }));
    },
    setOnline: (status) => {
      dispatch(appSetOnline(status));
    },
    getUsers: ({ token, last_updated }) => {
      dispatch(fetchUsers({ token, last_updated }));
    },
    getActions: ({ token, last_updated }) => {
      dispatch(fetchActions({ token, last_updated }));
    },
    getCategories: ({ token, last_updated }) => {
      dispatch(fetchCategories({ token, last_updated }));
    },
    getFaults: ({ token, last_updated }) => {
      dispatch(fetchFaults({ token, last_updated }));
    },
    getReportTypes: ({ token, last_updated }) => {
      dispatch(fetchReportTypes({ token, last_updated }));
    },
    setToken: (token) => {
      dispatch(setToken(token));
    },
    setUser: (token) => {
      dispatch(setUser(token));
    },
    getUser: async (token) => {
      await dispatch(fetchUser(token));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    token: selectToken(state),
    user: selectUser(state),
    online: selectOnline(state),
    users: selectUsersAsArray(state),
    actions: selectActionsAsArray(state),
    categories: selectCategoriesAsArray(state),
    faults: selectFaultsAsArray(state),
    reportTypes: selectReportTypesAsArray(state),
    offlineReports: allReportsToArray(selectMyReports(state)),
    reports: allReportsToArray(
      mergeAllReports({
        online: selectPendingReports(state),
        offline: selectUncheckedReports(state),
      })
    ),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Start);

function App() {
  return (
    <Provider store={store}>
      <Container />
    </Provider>
  );
}
export default App;
