import React from "react";
import styles from "./styles.module.css";
import TextField from "@material-ui/core/TextField";

const VehicleInspect = ({ info, setInfo, vehicle, editing, setEditing }) => {
  const handleChange = (key, change, isEditing = undefined) => {
    change();
    setEditing({ ...editing, [key]: isEditing });
  };

  const commonProps = (objKey) => ({
    className: styles.input,
    InputProps: {
      className: styles.input,
    },
    variant: "outlined",
    onChange: (e) => {
      handleChange(
        objKey,
        () => setInfo({ ...info, [objKey]: e.target.value }),
        ((vehicle && vehicle[objKey]) || "") !== e.target.value
      );
    },
  });

  return (
    <div className={styles.container}>
      {Object.keys(info).map((key) => (
        <TextField
          key={key}
          margin="normal"
          label={key}
          data-cy={key}
          value={info[key]}
          {...commonProps(key)}
        />
      ))}
    </div>
  );
};

export default VehicleInspect;
