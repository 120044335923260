import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./styles.module.css";
import IconButtonHorizontal from "../iconbuttonhorizontal";
import { MyTheme } from "common/modules/colors";
import Brand from "../brand";
import IconButton from "../iconbutton";

const SideMenu = ({ reportCount, showLogoutWarning }) => {
  const [open, setOpen] = React.useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: MyTheme.colors.primary }}
    >
      <div className={styles.logomenu}>
        {matches ? (
          <Brand color={MyTheme.colors.onPrimary} />
        ) : (
          <IconButton
            dataCy="menu"
            icon="menu"
            iconStyle="outlined"
            color={MyTheme.colors.onPrimarySubtle}
            activeColor={MyTheme.colors.onPrimary}
            onClick={() => setOpen(!open)}
            badgeCount={reportCount}
          />
        )}
      </div>
      <div className={styles.toplinks}>
        <IconButtonHorizontal
          title={(open || matches) && "Reports"}
          dataCy="reports"
          icon="assignment"
          iconStyle="outlined"
          color={MyTheme.colors.onPrimarySubtle}
          activeColor={MyTheme.colors.onPrimary}
          link="/reports"
          badgeCount={reportCount}
        />
        <IconButtonHorizontal
          title={(open || matches) && "Vehicles"}
          dataCy="vehicles"
          icon="local_shipping"
          iconStyle="outlined"
          color={MyTheme.colors.onPrimarySubtle}
          activeColor={MyTheme.colors.onPrimary}
          link="/vehicles"
        />
        <IconButtonHorizontal
          title={(open || matches) && "Schedule"}
          dataCy="schedule"
          icon="pending_actions"
          iconStyle="outlined"
          color={MyTheme.colors.onPrimarySubtle}
          activeColor={MyTheme.colors.onPrimary}
          link="/schedule"
        />
      </div>
      <div className={styles.bottomlinks}>
        <IconButtonHorizontal
          title={(open || matches) && "Settings"}
          dataCy="settings"
          icon="settings"
          iconStyle="outlined"
          color={MyTheme.colors.onPrimarySubtle}
          activeColor={MyTheme.colors.onPrimary}
          link="/settings"
        />
        <IconButtonHorizontal
          onClick={showLogoutWarning}
          title={(open || matches) && "Logout"}
          dataCy="logout"
          icon="power_settings_new"
          iconStyle="outlined"
          color={MyTheme.colors.onPrimarySubtle}
          activeColor={MyTheme.colors.onPrimary}
          link="/logout"
        />
      </div>
    </div>
  );
};

export default SideMenu;
