import React from "react";
import styles from "./styles.module.css";
import { connect } from "react-redux";

import {
  deleteReport,
  selectToken,
  selectReportsByVehicle,
  selectReportsOrderByVehicle,
  fetchReports,
} from "common/ducks/api";

import { MyTheme } from "common/modules/colors";
import ReportsSearchResults from "../reportssearchresults";
import { deleteMyReport, selectMyReportsByVehicle } from "common/ducks/report";
import { reportSearchFilter } from "common/modules/search-filters";
import {
  mergedReportsToArray,
  mergeAllReports,
  mergeReportsOfVehicle,
} from "common/modules/utils";
import SearchField from "../searchfield";
import { LinearProgress } from "@material-ui/core";
const colors = MyTheme.colors;

class History extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      filtered: [],
    };
  }

  componentDidMount() {
    this.SearchFilterFunction("");
  }

  componentDidUpdate(prevProps) {
    if (this.props.reports !== prevProps.reports) {
      this.SearchFilterFunction(this.state.searchInput);
    }
  }

  filteredToReports = () => {
    return this.state.filtered;
  };

  SearchFilterFunction = (input) => {
    let filtered = reportSearchFilter(this.props.reports, input);

    this.setState({
      filtered: filtered,
      searchInput: input,
    });
  };

  handleDelete = async ({ id, vehicle }) => {
    if (id < 0) {
      await this.props.deleteMyReport({ token: this.props.token, id, vehicle });
    } else {
      await this.props.deleteReport({ token: this.props.token, id, vehicle });
    }
    this.props.getReports({
      token: this.props.token,
      last_updated: this.props.last_updated,
    });
    // this.SearchFilterFunction("");
  };

  render() {
    return (
      <div className={styles.container} data-cy="history">
        <SearchField
          label="Reports"
          value={this.state.searchInput}
          onChange={(e) => this.SearchFilterFunction(e.target.value)}
        />
        {this.props.isFetching && <LinearProgress />}

        {this.state.filtered.length > 0 ? (
          <ReportsSearchResults
            reports={this.filteredToReports()}
            handleDelete={this.handleDelete}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: selectToken(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteMyReport: async ({ id, vehicle }) => {
      await dispatch(deleteMyReport({ id, vehicle }));
    },
    deleteReport: async ({ token, id, vehicle }) => {
      await dispatch(deleteReport({ token, id, vehicle }));
    },
    getReports: async ({ token, last_updated, vehicle }) => {
      await dispatch(fetchReports({ token, last_updated, vehicle }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
