import * as React from "react";
import styles from "./styles.module.css";
import { MyTheme } from "common/modules/colors";

import { ListItem, ListItemText } from "@material-ui/core";

import { Link, useRouteMatch } from "react-router-dom";
import ReportSearchResult from "../reportsearchresult";
// const ReportsSearchResults = ({ vehicles }) => <div>Hello world!</div>;
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";

import AlertDialog from "../dialog";

const ListItemLink = (props) => {
  return <ListItem divider button component={Link} {...props} />;
};

class ReportsSearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDialogOpen: false, reportRightClicked: null };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 60,
    });
  }

  handleRightClick = (e, reportIndex) => {
    this.setState({ isDialogOpen: true, reportRightClicked: reportIndex });
    e.preventDefault();
    return false;
  };

  handleDelete = () => {
    this.props.handleDelete({
      index: this.state.reportRightClicked,
      id: this.props.reports[this.state.reportRightClicked].id,
      vehicle:
        this.props.reports[this.state.reportRightClicked].vehicleInfo.vehicle,
    });
    this.setState({ isDialogOpen: false });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  RenderRow = ({ index, style, key, parent }) => (
    <CellMeasurer
      key={key}
      cache={this.cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <ListItemLink
        onContextMenu={(e) => this.handleRightClick(e, index)}
        key={this.props.reports[index].id}
        to={`/reports/${this.props.reports[index].vehicleInfo.vehicle}/${this.props.reports[index].id}`}
        style={style}
      >
        <ReportSearchResult
          vehicle={this.props.vehicle}
          report={this.props.reports[index]}
        />
      </ListItemLink>
    </CellMeasurer>
  );

  render() {
    return (
      <div className={styles.container} data-cy="history-list">
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={this.cache}
              rowHeight={this.cache.rowHeight}
              rowRenderer={this.RenderRow}
              rowCount={this.props.reports.length}
              overscanRowCount={3}
            />
          )}
        </AutoSizer>

        <AlertDialog
          title="Delete report?"
          message="Do you want to delete this report?\nThis can not be undone!"
          okTitle="Delete"
          open={this.state.isDialogOpen}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDelete}
        />
      </div>
    );
  }
}

export default ReportsSearchResults;
